import { EFilterType, FilterObjectClass, FilterOption } from "@reactdbclient/types.client.common";
import { ICard } from "./lib";
import { FilterOptionExtended } from "./Filters/FilterState";

export const generateFiltersFromFields = (fields: FilterOption[]) =>
  fields.map((f) => new FilterObjectClass({ uid: f.uid, field: f.uid, value: "", label: f.label, type: f.type }));

export const generateFiltersFromFieldsExtended = (fields: FilterOptionExtended[]) =>
  fields.map(
    (f) =>
      new FilterObjectClass({
        uid: f.filter.uid,
        field: f.filter.uid,
        value: f.value || "",
        label: f.filter.label,
        type: f.filter.type,
      })
  );

export const filterCards =
  <TypeKey, ICardProps extends ICard<TypeKey>>(filters: FilterObjectClass[]) =>
  (card: ICardProps) => {
    return filters.every((filter) => {
      const { type, field, value } = filter;
      switch (type) {
        case EFilterType.selectMulti: {
          const selection = value && (value as string).split(",");
          if (selection && selection.indexOf(card[field]) === -1) return false;
          break;
        }
        case EFilterType.text: {
          if (value && !card[field].includes(value)) return false;
          break;
        }
        default:
          throw Error(`Filter not setup for type: ${type}`);
      }
      return true;
    });
  };
