import { EFilterType, ILabelled } from "@reactdbclient/types.client.common";
import { IBubblePlotAdditionalProps } from "../../components/DataViewDashboard/DataViewTypes/BubblePlotView/lib";
import { IDataDownloadAdditionalProps } from "../../components/DataViewDashboard/DataViewTypes/DataDownload/lib";
import { ISimplePlotAdditionalProps } from "../../components/DataViewDashboard/DataViewTypes/SimplePlot/lib";
import { ITag, TagId } from "../Tags/ITag";
import { ISimpleScatterAdditionalProps } from "../../components/DataViewDashboard/DataViewTypes/SimpleScatter/lib";
import { ISimpleBarAdditionalProps } from "../../components/DataViewDashboard/DataViewTypes/SimpleBar/lib";
import { IPieChartAdditionalProps } from "../../components/DataViewDashboard/DataViewTypes/PieChart/lib";
import { FilterOptionExtended } from "../../GenericComponents/CardBoard/Filters/FilterState";

export type DataViewId = number | string;

export enum EDataViewTypes {
  SIMPLE_PLOT = 1,
  BUBBLE_PLOT = 2,
  DATA_DOWNLOAD = 3,
  GAUGE_CHART = 4,
  SIMPLE_SCATTER = 5,
  SIMPLE_BAR = 6,
  PIE_CHART = 7,
}

export const DataViewTypes: Record<EDataViewTypes, DataViewId> = {
  [EDataViewTypes.SIMPLE_PLOT]: 1,
  [EDataViewTypes.BUBBLE_PLOT]: 2,
  [EDataViewTypes.DATA_DOWNLOAD]: 3,
  [EDataViewTypes.GAUGE_CHART]: 4,
  [EDataViewTypes.SIMPLE_SCATTER]: 5,
  [EDataViewTypes.SIMPLE_BAR]: 6,
  [EDataViewTypes.PIE_CHART]: 7,
};

const DataViewFilterOptions: ILabelled[] = [
  {
    uid: EDataViewTypes.SIMPLE_PLOT,
    label: "Line plots",
  },
  {
    uid: EDataViewTypes.BUBBLE_PLOT,
    label: "Single axis scatter plots",
  },
  {
    uid: EDataViewTypes.GAUGE_CHART,
    label: "Gauge charts",
  },
  {
    uid: EDataViewTypes.SIMPLE_SCATTER,
    label: "Scatter plots",
  },
  {
    uid: EDataViewTypes.SIMPLE_BAR,
    label: "Bar Plots",
  },
  {
    uid: EDataViewTypes.PIE_CHART,
    label: "Pie charts",
  },
  {
    uid: EDataViewTypes.DATA_DOWNLOAD,
    label: "Data download",
  },
];

export type TDataAdditionalProps =
  | ISimplePlotAdditionalProps
  | IBubblePlotAdditionalProps
  | IDataDownloadAdditionalProps
  | ISimpleScatterAdditionalProps
  | ISimpleBarAdditionalProps
  | IPieChartAdditionalProps;

export interface IDataView<AdditionalProps extends TDataAdditionalProps = TDataAdditionalProps> {
  id: string | number;
  label: string;
  type: EDataViewTypes;
  logo?: string;
  description: string;
  tags?: (ITag | TagId)[];
  additionalProps: AdditionalProps; // TODO: This should be in full only
}

export interface IDataViewFull<AdditionalProps extends TDataAdditionalProps = TDataAdditionalProps>
  extends IDataView<AdditionalProps> {
  longDescription?: string;
  allowDataDownload: boolean;
}

export const DataViewFields = DataViewFilterOptions.map(
  (f) =>
    new FilterOptionExtended(
      {
        uid: String(f.uid),
        field: String(f.uid),
        label: f.label,
        type: EFilterType.bool,
      },
      {
        icon: "/images/icons/activities/ANALYSIS_SMALL.png",
        value: true,
      }
    )
);
