import React, { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import {
  FaqGroupStyle,
  FaqGroupWrapStyle,
  FaqItemContentStyle,
  FaqItemHeadingStyle,
  FaqItemStyle,
  FaqItemWrapStyle,
  FaqButtonGroupStyle,
} from "../../styles/faq";
import { EFaqAudience, FaqGroups, IFaqGroup, IFaqItem } from "./lib";
import faqEntries from "./faqEntries";
// import { SearchAndSelectDropdown } from "@react_db_client/components.search-and-select-dropdown";
import { StyledSASDropdown } from "../../styles/form";
import { ButtonStyle } from "../../styles/button";
import { BoxoutContactStyle } from "../../styles/hero";

export interface IFaqItemProps {
  open?: boolean;
  data: IFaqItem;
}
export const FaqItem = ({ data: { label, uid, content, highlighted }, open = false }: IFaqItemProps) => {
  const [isOpen, setIsOpen] = React.useState(open);
  const [icon, setIcon] = React.useState(faCaretDown);

  useEffect(() => {
    setIsOpen(open);
    setIcon(open ? faCaretUp : faCaretDown);
  }, [open]);

  return (
    <FaqItemStyle>
      <FaqItemHeadingStyle
        onClick={() => {
          setIsOpen((prev) => !prev);
          setIcon(icon === faCaretDown ? faCaretUp : faCaretDown);
        }}
        highlighted={highlighted}
      >
        <h3 id={uid}>
          {label}
          <FontAwesomeIcon size="xl" style={{ float: "right", marginLeft: "0.5rem" }} icon={icon} />
        </h3>
      </FaqItemHeadingStyle>
      <FaqItemContentStyle isOpen={isOpen}>
        <div>{content}</div>
      </FaqItemContentStyle>
    </FaqItemStyle>
  );
};

export const getNodeText = (node) => {
  if (["string", "number"].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(getNodeText).join("");
  if (typeof node === "object" && node) return getNodeText(node.props.children);
};

const filterBySearchVal = (searchVal: string) => (item: IFaqItem) => {
  const labelAsString = typeof item.label === "string" ? item.label : getNodeText(item.label);
  if (labelAsString.toLowerCase().includes(searchVal.toLowerCase())) return true;
  if (getNodeText(item.content).toLowerCase().includes(searchVal.toLowerCase())) return true;
  return false;
};

const filterByAudienceType = (audienceType: any) => (item: IFaqItem) => {
  if (audienceType === "all" || item.audience.includes(audienceType)) return true;
  return false;
};

export interface IFaqGroupProps {
  label: string;
  entries: IFaqItem[];
  uidToShow?: string;
}
export const FaqGroup = ({ label, entries, uidToShow }) => {
  return (
    <FaqGroupStyle>
      <h2 id={label.replace(/\s/g, "")}>{label}</h2>
      <FaqItemWrapStyle>
        {entries.map((faqItem) => (
          <FaqItem key={faqItem.uid} data={faqItem} open={uidToShow ? faqItem.uid === uidToShow : false} />
        ))}
      </FaqItemWrapStyle>
    </FaqGroupStyle>
  );
};

const allGroup = { uid: "all", label: "All" };

const getGroupOptions: () => Promise<(IFaqGroup | { uid: string; label: string })[]> = async () => [
  allGroup,
  ...Object.values(FaqGroups),
];

export const FaqButtonGroup = ({ buttons, onClickCallback, defaultId = null }) => {
  const [clickedId, setClickedId] = React.useState(defaultId);

  const handleClick = (event, id) => {
    setClickedId(id);
    onClickCallback(event);
  };

  return (
    <FaqButtonGroupStyle>
      {buttons.map((buttonLabel, i) => (
        <ButtonStyle
          key={i}
          name={buttonLabel}
          onClick={(event) => handleClick(event, i)}
          className={i === clickedId ? "active" : null}
          title={"show data for " + buttonLabel}
        >
          {buttonLabel}
        </ButtonStyle>
      ))}
    </FaqButtonGroupStyle>
  );
};

const Faq: React.FC = () => {
  const [currentGroup, setCurrentGroup] = React.useState(allGroup);
  const [searchVal, setSearchVal] = React.useState("");
  const [queryParameters] = useSearchParams();
  const paramAudience = queryParameters.get("audience");

  const audienceButtonOptions = [
    EFaqAudience.PUPILS,
    EFaqAudience.STAFF,
    EFaqAudience.PARENTS,
    EFaqAudience.GOVERNORS,
    EFaqAudience.OTHER,
    "all",
  ];
  const defaultAudienceIndex = audienceButtonOptions.findIndex((x) => x === paramAudience);

  const [audienceType, setAudienceType] = React.useState(
    defaultAudienceIndex >= 0 ? paramAudience : EFaqAudience.PUPILS
  );

  const onChangeMiddleware = React.useCallback((selectedData) => {
    selectedData && setCurrentGroup(selectedData);
  }, []);

  const changeAudienceType = (event) => {
    setAudienceType(event.target.name);
  };

  const { hash } = useLocation();
  const uid = hash?.replace("#", "");

  return (
    <>
      <BoxoutContactStyle style={{ marginBottom: "2rem", padding: "2rem", overflow: "inherit" }}>
        <p>
          <b>Find answers to commonly asked questions using the search tools below.</b>
        </p>
        <br></br>
        <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
          <label style={{ minWidth: "12rem", marginBottom: "0.2rem" }} htmlFor="groupSelect">
            <b>Filter FAQ Groups:</b>
          </label>
          <StyledSASDropdown
            id="groupSelect"
            searchFunction={getGroupOptions as any} // TODO: Fix types
            initialValue={currentGroup}
            handleSelect={onChangeMiddleware}
            allowEmptySearch
            // allowInputOnly={false}
            onChange={(e) => onChangeMiddleware((e.target as HTMLInputElement).value)}
            searchFieldTargetField="label"
            searchDelay={0}
            labelField="label"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column", marginBottom: "1rem" }}>
          <label style={{ minWidth: "12rem", marginBottom: "0.2rem" }} htmlFor="searchInput">
            <b>Search FAQ:</b>
          </label>
          <input
            style={{ flexGrow: 1 }}
            id="searchInput"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label style={{ minWidth: "12rem", marginBottom: "0.2rem" }}>
            <b>Show info for:</b>
          </label>
          <FaqButtonGroup
            buttons={audienceButtonOptions}
            onClickCallback={changeAudienceType}
            defaultId={defaultAudienceIndex >= 0 ? defaultAudienceIndex : 0}
          />
        </div>
      </BoxoutContactStyle>
      <FaqGroupWrapStyle>
        {Object.values(FaqGroups)
          .map((f) => ({
            ...f,
            entries: faqEntries.filter((fe) => fe.group === f.uid).filter(filterBySearchVal(searchVal)),
          }))
          .map((f) => ({
            ...f,
            entries: f.entries.filter(filterByAudienceType(audienceType)),
          }))
          .filter((f) => f.entries.length > 0 && (currentGroup?.uid === "all" || currentGroup?.uid === f.uid))
          .map((f) => (
            <FaqGroup label={f.label} key={f.uid} entries={f.entries} uidToShow={uid} />
          ))}
      </FaqGroupWrapStyle>
    </>
  );
};

export default Faq;
