import React, { createContext } from "react";
import { FilterOption } from "@reactdbclient/types.client.common";
import { IFilterOptionsArgs } from "@reactdbclient/types.client.common/dist/filter-object-class";
import { IUseManageFiltersArgs, IUseManageFiltersOutput, useManageFilters } from "./useManageFilters";

interface IFilterStateContext extends Omit<IUseManageFiltersOutput, "fieldsData"> {
  fieldsData: FilterOptionExtended[];
}

const NOT_IMPLEMENTED = (id: string) => () => {
  throw new Error(`${id} - FUNCTION NOT IMPLEMENTED`);
};

// Create a new context for the filter state
const FilterStateContext = createContext<IFilterStateContext>({
  addFilter: NOT_IMPLEMENTED("addFilter"),
  deleteFilter: NOT_IMPLEMENTED("deleteFilter"),
  updateFilter: NOT_IMPLEMENTED("updateFilter"),
  updateFilterValue: NOT_IMPLEMENTED("updateFilterValue"),
  clearFilters: NOT_IMPLEMENTED("clearFilters"),
  updateFieldTarget: NOT_IMPLEMENTED("updateFieldTarget"),
  updateOperator: NOT_IMPLEMENTED("updateOperator"),
  setSearchStringFilter: NOT_IMPLEMENTED("setSearchStringFilter"),
  filters: [],
  fieldsData: [],
  setFilters: NOT_IMPLEMENTED("setFilters"),
});

export class FilterOptionExtended {
  filter: FilterOption;
  icon: string;
  value?: any;

  constructor(args: IFilterOptionsArgs<any, any>, additionalArgs: { icon: string; value?: any }) {
    this.icon = additionalArgs.icon;
    this.value = additionalArgs.value;
    this.filter = new FilterOption(args);
  }
}

export interface IFilterStateProvider extends Omit<IUseManageFiltersArgs, "fieldsData"> {
  children: React.ReactNode;
  fieldsData: FilterOptionExtended[];
}

// Create a provider component to wrap your app and provide the filter state
const FilterStateProvider = ({ children, fieldsData, ...args }: IFilterStateProvider) => {
  const filterManagerFields = fieldsData.reduce((acc, field) => {
    acc[field.filter.uid] = field.filter;
    return acc;
  }, {});
  // Define the initial state of your filter
  const filtersManager = useManageFilters({ ...args, fieldsData: filterManagerFields });

  return (
    <FilterStateContext.Provider value={{ ...filtersManager, fieldsData }}>{children}</FilterStateContext.Provider>
  );
};

export { FilterStateContext, FilterStateProvider };
