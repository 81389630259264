import { FilterObjectClass, FilterOption } from "@reactdbclient/types.client.common";
 import styled from "styled-components";
import { cloneDeep } from "lodash";
import { ButtonStyle } from "../../../../styles/button";

export const OptionList = styled.ul`
  list-style: none;
`;

export interface IOptionItemProps {
  selected?: boolean;
}

export const OptionItem = styled.ul<IOptionItemProps>`
  button {
    background: ${({ selected, theme }) => (selected ? theme.colors.main : theme.colors.grey1)};
  }
`;

export interface ISelectMultiFilterProps {
  filter: FilterObjectClass;
  updateFilter: (newFilter: FilterObjectClass) => void;
  fieldData: FilterOption;
}

export const SelectMultiFilter = ({ filter, updateFilter, fieldData }: ISelectMultiFilterProps) => {
  const currentSelection = (filter.value as string)?.split(",") || [];
  const selectOption = (optId) => {
    const isSelected = currentSelection.indexOf(optId) !== -1;
    const newSelection = isSelected ? currentSelection.filter((s) => s !== optId) : [...currentSelection, optId];
    const newValue = newSelection.join(",");
    const newFilter = cloneDeep(filter);
    newFilter.value = newValue;
    updateFilter(newFilter);
  };
  return (
    <OptionList>
      {fieldData.typeArgs.options.map((opt) => (
        <OptionItem key={opt.uid} selected={currentSelection.indexOf(String(opt.uid)) !== -1}>
          <ButtonStyle onClick={() => selectOption(opt.uid)}>{opt.label}</ButtonStyle>
        </OptionItem>
      ))}
    </OptionList>
  );
};
