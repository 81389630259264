import React from "react";
import { useFormContext, useController, ValidateResult, Validate } from "react-hook-form";
import { FilterObjectClass, IDocument } from "@reactdbclient/types.client.common";
import {
  SearchAndSelectDropdown,
  ISearchAndSelectDropdownProps,
} from "@react_db_client/components.search-and-select-dropdown";
import { InputStyle } from "../../styles/form";
import { Label } from "./Label";
import { ErrorMessage } from "./ErrorMessage";

export interface ISelectOption {
  uid: number | string;
  label: string;
}

export interface IInputProps<Item extends IDocument>
  extends React.HTMLProps<HTMLInputElement>,
    Partial<ISearchAndSelectDropdownProps<Item>> {
  name: string;
  label?: string;
  required?: boolean;
  validate?: Record<string, Validate<any, any>> | ((value: any) => ValidateResult | Promise<ValidateResult>);
  allowInputOnly?: boolean;
  searchFunction?: (filters?: FilterObjectClass[]) => Promise<Item[]>;
  options?: ISelectOption[];
  validateOnChange?: boolean;
}

export interface IItem {
  uid: string | number;
  label: string;
}

const validateSelect = (name, options, required) => (v) => {
  if (!required && !v) return true;
  if (required && !v) return `${name} is undefined`;
  if (options.find((o) => o.uid === v.uid || o.uid === v || o.label === v)) return true;
  return `${v} is not a valid selection.`;
};

const defaultSearchFunc = (options) => async () => options;

export const Select = <Item extends IItem>(props: IInputProps<Item>): JSX.Element => {
  const {
    type,
    label,
    name,
    required,
    options,
    validateOnChange,
    searchFunction,
    validate,
    allowInputOnly,
    ...inputProps
  } = props as IInputProps<Item>;
  const { control, trigger } = useFormContext();

  if (type && type !== "select") throw Error(`Attempted to use select input for none select type: ${type}`);
  if (!name) throw Error("Must supply name");

  const validateOverride = React.useMemo(
    () => validate || validateSelect(label, options, required),
    [validate, label, options, required]
  );

  const searchFunctionOverride = React.useMemo(
    () => searchFunction || defaultSearchFunc(options),
    [searchFunction, options]
  );

  const {
    field: { onChange, onBlur, ref, value, ...fieldProps },
  } = useController({
    name,
    control,
    rules: { required: required ? "Required" : undefined, validate: validateOverride },
  });

  const onChangeMiddleware = (nameInner, _selectedData?) => {
    const newVal = allowInputOnly ? _selectedData || nameInner : _selectedData || "";
    onChange({ target: { value: newVal } });
    if (validateOnChange) trigger(name);
  };

  const onBlurMiddleware = () => {
    onBlur();
    if (validateOnChange) trigger(name);
  };

  return (
    <InputStyle>
      <Label name={name} label={label} required={required} />
      <SearchAndSelectDropdown
        searchFunction={searchFunctionOverride}
        initialValue={typeof value === "number" ? String(value) : value}
        handleSelect={(v) => onChangeMiddleware(v.label, v)}
        allowEmptySearch
        searchFieldRef={ref as any}
        onChange={(e) => onChangeMiddleware((e.target as HTMLInputElement).value, null)}
        searchFieldTargetField="label"
        searchDelay={500}
        onBlur={onBlurMiddleware}
        {...inputProps}
        {...fieldProps}
        id={name}
        name={name}
      />
      <ErrorMessage name={name} />
    </InputStyle>
  );
};
