import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./_loadingPanel.scss";

let loadingCount = 1;

const getPortalRoot = (rootid = "loadingroot") => {
  let portalRoot = document.getElementById(rootid);
  if (!portalRoot) {
    portalRoot = document.createElement("div");
    portalRoot.setAttribute("id", rootid);
    document.body.appendChild(portalRoot);
  }
  if (!portalRoot) throw Error("Could not create loading panel portal root");
  return portalRoot;
};

export type LoadingPanelProps = {
  className?: string;
  open: boolean;
  message: string;
  collapsed?: boolean;
  portalRoot?: string;
};

export const LoadingPanel: React.FC<LoadingPanelProps> = ({ className, open, message = "Loading", collapsed, portalRoot }) => {
  const [z] = useState(loadingCount);
  const root = React.useMemo(() => getPortalRoot(portalRoot), [portalRoot]);
  useEffect(() => {
    loadingCount += 1;
    return () => {
      loadingCount -= 1;
    };
  }, []);

  const classname = ["loadingPanel", className, collapsed ? "collapsed" : "", open ? "" : "hidden"].join(" ");

  return ReactDOM.createPortal(
    <div className={classname} style={{ display: open ? "inherit" : "none", zIndex: z * 100 }}>
      <div className="loadingPanel_message" >{message}</div>
    </div>,
    root
  );
};

export default LoadingPanel;
