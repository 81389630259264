/* istanbul ignore file */
import React from "react";
import { screen, within } from "@testing-library/react";
import { MatcherFunction } from "@testing-library/react";
import { PopupProvider } from "@react_db_client/components.popup-panel-v2";
import { MemoryRouter } from "react-router-dom";
import { EChartsOption } from "echarts-for-react";
import nodeCrypto from "crypto";
import { Provider } from "react-redux";
import { render } from "@testing-library/react";
import { EComparisons, FilterObjectClass } from "@reactdbclient/types.client.common";
import { lightTheme } from "../../styles/themes";
import store, { getStore } from "../../Redux/reduxStore";
import StyleProviders from "../../styles/StyleProviders";
import { AUTH_SCOPE, AUTH_URL, CLIENT_KEY, HELP_CONTACT } from "../../config";
import { IUser } from "../../lib/User/IUser";
import initialState from "../../Redux/initialState";
// import { initialStateLoggedIn } from "../../Redux/initialState.mock";
import ErrorBoundary from "../../GenericComponents/ErrorBoundary";
import { KeywordProvider } from "../../components/TextFormatter/KeyWordProvider";
import { exampleKeywordMap } from "../../components/TextFormatter/examples";
import { NotificationBar } from "../../GenericComponents/NotificationBar";
import { NewAchievementPopup } from "../../components/Achievements/NewAchievementPopup";
import { StoryProviders } from "../storybook/storybookWrappers";
import ApiProvider, { IApiContext } from "../../Redux/Api/ApiContext";
import { IKeyword } from "../../lib/Keyword/IKeyword";

type Query = (f: MatcherFunction) => HTMLElement;
type QueryAsync = (f: MatcherFunction) => Promise<HTMLElement>;

/* https://stackoverflow.com/a/56859650/10259813 */
export const withMarkup =
  (query: Query) =>
  (text: string): HTMLElement =>
    query((content: string, node: HTMLElement) => {
      const hasText = (node: HTMLElement) => node.textContent === text;
      const childrenDontHaveText = Array.from(node.children).every((child) => !hasText(child as HTMLElement));
      return hasText(node) && childrenDontHaveText;
    });

export const withMarkupAsync =
  (query: QueryAsync) =>
  async (text: string): Promise<HTMLElement> =>
    query((content: string, node: HTMLElement) => {
      const hasText = (node: HTMLElement) => node.textContent === text;
      const childrenDontHaveText = Array.from(node.children).every((child) => !hasText(child as HTMLElement));
      return hasText(node) && childrenDontHaveText;
    });

export const TestProviders = ({
  children,
  store = getStore(initialState),
  keywordMap = exampleKeywordMap,
  apiContextOverrides = {},
  ...routerProps
}: {
  children: React.ReactNode;
  store?: any;
  keywordMap?: IKeyword[];
  apiContextOverrides?: Partial<IApiContext>;
  [key: string]: any;
}) => {
  return (
    <MemoryRouter {...routerProps}>
      <ApiProvider apiContextOverrides={apiContextOverrides}>
        <StyleProviders theme={lightTheme}>
          <PopupProvider>
            <Provider store={store}>
              <NotificationBar />
              <NewAchievementPopup />
              <KeywordProvider keywordMap={keywordMap}>
                <ErrorBoundary addr={HELP_CONTACT} emailSubject="SAMHE App Error Report">
                  {children}
                </ErrorBoundary>
              </KeywordProvider>
            </Provider>
          </PopupProvider>
        </StyleProviders>
      </ApiProvider>
    </MemoryRouter>
  );
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const codeVerifier = "47DEQpj8HBSa-_TImW-5JCeuQeRkm5NMpJWZG3hSuFU";
const redirectUri = "http%3A%2F%2Flocalhost";
export const authQuery = `response_type=code&client_id=${CLIENT_KEY}&scope=${AUTH_SCOPE.split(" ").join(
  "+"
)}&redirect_uri=${redirectUri}&code_challenge=${codeVerifier}&code_challenge_method=S256&response_mode=query&state=%2F`; // TODO: Check what state is

export const authPath = `${AUTH_URL}?${authQuery}`;

export const waitForLoadLoggedIn = async () => {
  const pingTestComponent = screen.getByTestId("ping-test");
  await within(pingTestComponent).findByText("Connection Ok", {}, { timeout: 5000 });
};

/* Mock Text encoder as not available in test env */

declare module "crypto" {
  namespace webcrypto {
    const subtle: SubtleCrypto;
    const getRandomValues: any;
  }
}

class TextEncoder {
  encode: (_input?: string) => Uint8Array;
  constructor() {
    this.encode = (_input?: string) => new Uint8Array();
  }
}

class TextDecoder {
  decode: (_input?: Uint8Array) => "hello";
  constructor() {
    this.decode = (_input?: Uint8Array) => "hello";
  }
}

export function mockWindowForPKCE() {
  const realWindow = window;
  global.console.warn = jest.fn();

  /* Mock crypto functions */
  // @ts-ignore
  global.TextEncoder = TextEncoder;
  // @ts-ignore
  global.TextDecoder = TextDecoder;
  const currentLoc = window.location;
  // @ts-ignore
  delete window.location;
  const location = new URL(currentLoc.href);
  // @ts-ignore
  location.replace = jest.fn();
  // @ts-ignore
  window.location = location;

  window.crypto = {
    randomUUID: () => `randomuid-randomuid-randomuid-randomuid-randomuid`,
    subtle: nodeCrypto.webcrypto.subtle,
    getRandomValues: nodeCrypto.webcrypto.getRandomValues.bind(nodeCrypto.webcrypto),
  };
  return realWindow;
}

export async function renderReturnedFromLoginClient(user: IUser, App) {
  /* NOTE: Access code would be JWT in production */
  const accessCode = JSON.stringify({ userName: user.username });
  if (localStorage.getItem("ROCP_idToken")) throw Error("Make sure to clear localStorage after tests");
  localStorage.setItem("ROCP_loginInProgress", "true");
  localStorage.setItem("PKCE_code_verifier", codeVerifier);
  localStorage.setItem("ROCP_tokenExpire", "100000000000");
  localStorage.setItem("ROCP_refreshTokenExpire", "100000000000");
  localStorage.setItem("ROCP_refreshToken", "NOTFORPRODUCTION_REFRESH_TOKEN");
  localStorage.setItem("ROCP_idToken", "NOTFORPRODUCTION_ID_TOKEN");
  window.location.search = `${authQuery}&code=${accessCode}`;
  render(
    <TestProviders store={getStore(initialState)} initialEntries={[`/app?${authQuery}&code=${accessCode}`]}>
      <App />
    </TestProviders>
  );
  await waitForLoadLoggedIn();
}

export function setLoggedInState(user: IUser) {
  if (localStorage.getItem("ROCP_idToken")) throw Error("Make sure to clear localStorage after tests");
  localStorage.setItem("ROCP_loginInProgress", "false");
  localStorage.setItem("PKCE_code_verifier", codeVerifier);
  localStorage.setItem("ROCP_tokenExpire", "100000000000");
  localStorage.setItem("ROCP_refreshTokenExpire", "100000000000");
  localStorage.setItem("ROCP_refreshToken", "NOTFORPRODUCTION_REFRESH_TOKEN");
  localStorage.setItem("ROCP_idToken", "NOTFORPRODUCTION_ID_TOKEN");
  localStorage.setItem("ROCP_token", `"{\\"userName\\":\\"${user.username}\\"}"`);
}

export function setLoggedInTimeoutState(user: IUser) {
  if (localStorage.getItem("ROCP_idToken")) throw Error("Make sure to clear localStorage after tests");
  localStorage.setItem("ROCP_loginInProgress", "false");
  localStorage.setItem("PKCE_code_verifier", codeVerifier);
  localStorage.setItem("ROCP_tokenExpire", "0");
  localStorage.setItem("ROCP_refreshTokenExpire", "0");
  localStorage.setItem("ROCP_refreshToken", "NOTFORPRODUCTION_REFRESH_TOKEN");
  localStorage.setItem("ROCP_idToken", "NOTFORPRODUCTION_ID_TOKEN");
  localStorage.setItem("ROCP_token", `"{\\"userName\\":\\"${user.username}\\"}"`);
}

export async function renderLoggedIn(user: IUser, App, url: string = "/app") {
  setLoggedInState(user);
  render(
    <TestProviders store={getStore(initialState)} initialEntries={[url]}>
      <App />
    </TestProviders>
  );
  await waitForLoadLoggedIn();
}

// export async function renderLoggedInB(user: IUser, App) {
//   const initialStateActive = user ? initialStateLoggedIn(user) : initialState;

//   if (localStorage.getItem("ROCP_idToken")) throw Error("Make sure to clear localStorage after tests");
//   localStorage.setItem("ROCP_loginInProgress", "true");
//   localStorage.setItem("PKCE_code_verifier", codeVerifier);
//   localStorage.setItem("ROCP_tokenExpire", "100000000000");
//   localStorage.setItem("ROCP_refreshTokenExpire", "100000000000");
//   localStorage.setItem("ROCP_refreshToken", "NOTFORPRODUCTION_REFRESH_TOKEN");
//   localStorage.setItem("ROCP_idToken", "NOTFORPRODUCTION_ID_TOKEN");

//   const view = render(
//     <TestProviders store={getStore(initialStateActive)} initialEntries={[`/app/`]}>
//       <App />
//     </TestProviders>
//   );
//   await screen.findByText("SAMHE Web App");
//   return view;
// }

export const filterWithFilterObjects =
  <T,>(filters: FilterObjectClass[]) =>
  (item: T) => {
    return filters.every((filter) => {
      if (filter.type === "text" && filter.field === "_textSearch") {
        return JSON.stringify(item).includes(filter.value);
      }
      if (filter.type === "text" && filter.operator === EComparisons.CONTAINS) {
        return item[filter.field].include(filter.value);
      }
      if (filter.type === "text" && filter.operator === EComparisons.EQUALS) {
        return item[filter.field] === filter.value;
      }
      console.error(`Invalid Filter: ${JSON.stringify(filters)}`);
      throw Error(`Filter type not implemented: ${filter.type}`);
    });
  };

const MinProviders = ({ children }) => {
  return (
    <Provider store={store}>
      <StyleProviders theme={lightTheme}>{children}</StyleProviders>
    </Provider>
  );
};

const customRender = (ui: any, options = {}) => render(ui, { wrapper: MinProviders, ...options });

// re-export everything
export * from "@testing-library/react";

// override render method
export { customRender as render };

export const renderStory = async (Story, user?: IUser) => {
  render(
    <StoryProviders user={user}>
      <Story {...Story.args} />
    </StoryProviders>
  );
  if (Story.isReady) await Story.isReady();
  const storyContent = screen.getByTestId("contentWrap");
  return { storyContent };
};

/* Echarts is mocked so that the options prop is used as the text content */
export const getEchartOptionProp = (containerQuery): EChartsOption => {
  return JSON.parse(containerQuery.getAllByTestId("echart_mock_options")[0].textContent) as EChartsOption;
};

export function parseJwtBrowser(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function parseJwt(token) {
  // NOTE: Currently don't encrypt token in local dev
  const tokenInner = token.replace("Bearer ", "").replace("?response_type=code", "");
  try {
    // return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
    return JSON.parse(tokenInner);
  } catch (error) {
    parseJwtBrowser(tokenInner);
  }
}
