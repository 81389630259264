import { FilterObjectClass } from "@reactdbclient/types.client.common";
import { SERVER_URL } from "../config";
import { IAchievementCategory } from "../lib/Achievements/EAchievementCategory";
import { IAchievementUserNested, IUserScoreUpdates } from "../lib/Achievements/IAchievement";
import { getDataFetch, queryDetailedObjectToString, throwErrors } from "./apiHelpers";

export interface IApiGetAchievementsResponse {
  data: {
    result: IAchievementUserNested[];
  };
}

export const apiGetAchievements = async (
  filters: FilterObjectClass[],
  accessToken: string
): Promise<IAchievementUserNested[]> => {
  if (!accessToken) throw Error("Missing access token");
  const filterString = filters && queryDetailedObjectToString(filters);
  // let url = encodeURI(`${SERVER_URL}/Achievements/Summary`);
  let url = encodeURI(`${SERVER_URL}/Achievement/GetUserAchievementProgresses`);

  const queryString = [filterString].filter((f) => f != null).join("&");
  if (queryString) url = url.concat("?").concat(queryString.toString());
  const headers = { Authorization: "Bearer " + accessToken };

  return getDataFetch<IApiGetAchievementsResponse>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => {
      return response.json.data.result;
    });
};

export interface IUserScoreResponse {
  category: Omit<IAchievementCategory, "color" | "logo" | "description">;
  score: number;
}
export interface IApiGetUserScoreResponse {
  data: {
    result: IUserScoreResponse[];
  };
}

export const apiGetUserScore = async (accessToken: string): Promise<IUserScoreUpdates> => {
  if (!accessToken) throw Error("Missing access token");
  const url = encodeURI(`${SERVER_URL}/UserScore/GetUserScores`);

  const headers = { Authorization: "Bearer " + accessToken };

  return getDataFetch<IApiGetUserScoreResponse>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => {
      return response.json.data.result.reduce(
        (acc, v) => ({ ...acc, [v.category.id]: v.score }),
        {} as IUserScoreUpdates
      );
    });
};

export const apiAchievements = {
  getAll: apiGetAchievements,
  getScore: apiGetUserScore,
};
