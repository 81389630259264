/* eslint-disable no-underscore-dangle */
import cloneDeep from "lodash/cloneDeep";
import deepMerge from "lodash/merge";
import { FilterObjectClass } from "@reactdbclient/types.client.common";
import { mockSchoolEstablishments, mockSchools } from "../dummyData";
import { DEMO_ACHIEVEMENTS_ALL, DEMO_ACHIEVEMENTS_USER } from "../dummyData/achievements";
import { DEMO_ACTIVITIES, DEMO_USER_ACTIVITIES } from "../dummyData/activities";
import { mockMonitorRequests } from "../dummyData/monitorRequests";
import { mockServerMonitors } from "../dummyData/monitors";
import { demoUsers } from "../dummyData/users";
import { IActivity, IActivityUser, IActivityUserData, IActivityUserDataNotStarted } from "../lib/Activities/IActivity";
import { IUser } from "../lib/User/IUser";
import { IMonitorRequest } from "../lib/formSubmissions/IMonitorRequest";
import { ISchoolEstablishment } from "../lib/formSubmissions/ISchoolEstablishment";
import { IMonitorServerState } from "../lib/Monitor/IMonitor";
import { ISchoolAppDetails, ISchoolValidated } from "../lib/School/ISchoolDetails";
import { filterWithFilterObjects } from "../utils/testing/test-utils";

const initialState = {
  Activities: [],
  Achievements: [],
  AchievementsUser: [],
  ActivityUserProgresses: [] as (IActivityUserData | IActivityUserDataNotStarted)[],
  SchoolUsers: [] as IUser[],
  MonitorRequests: [] as IMonitorRequest[],
  SchoolsValidated: [] as (ISchoolValidated & ISchoolAppDetails)[],
  Schools: [] as ISchoolAppDetails[],
  SchoolEstablishments: [] as ISchoolEstablishment[],
  Monitors: [] as IMonitorServerState[],
};

// eslint-disable-next-line no-underscore-dangle
global.__data__ = global.__data__ || cloneDeep(initialState);

export type Collection =
  | "Activities"
  | "Achievements"
  | "AchievementsUser"
  | "ActivityUserProgresses"
  | "SchoolUsers"
  | "MonitorRequests"
  | "SchoolsValidated"
  | "Schools"
  | "SchoolEstablishments"
  | "Monitors";

const idKey = "id";

export const getDoc = <T>(id, collection: Collection, idKeyOverride = idKey): T => {
  if (global.__data__[collection] === undefined) throw new Error(`Collection is not defined!: ${collection}`);
  const i = global.__data__[collection].findIndex(
    (d) => (d[idKeyOverride] && String(d[idKeyOverride])) === (id && String(id))
  );
  const result = global.__data__[collection][i];
  if (!result) {
    console.info(global.__data__[collection]);
    throw Error(`Could not find ${id} in ${collection}, using idKey: ${idKeyOverride}`);
  }
  return result;
};

export const getDocs = <T>(collection: Collection, filters?: FilterObjectClass[]): T[] => {
  if (global.__data__[collection] === undefined) throw new Error(`Collection is not defined!: ${collection}`);
  return Object.values<T>(global.__data__[collection]).filter(filters ? filterWithFilterObjects(filters) : () => true);
};

export const putDoc = (id, collection: Collection, data, idKeyOverride = idKey) => {
  if (global.__data__[collection] === undefined) throw new Error(`Collection is not defined!: ${collection}`);
  const i = global.__data__[collection].findIndex(
    (d) => (d[idKeyOverride] && String(d[idKeyOverride])) === (id && String(id))
  );
  if (!global.__data__[collection][i]) {
    console.info(global.__data__[collection]);
    throw Error(`Could not find ${id} in ${collection}, using idKey: ${idKeyOverride}`);
  }
  Object.assign(global.__data__[collection][i], data);
  return global.__data__[collection][i];
};

export const getId = (collection: Collection) => collection.length;

export const addDoc = <T>(id, collection: Collection, data: T) => {
  if (global.__data__[collection] === undefined) throw new Error(`Collection is not defined!: ${collection}`);
  global.__data__[collection].push(data);
  return data;
};

export const initCollection = <T>(collection: Collection, data: T[]) => {
  global.__data__[collection] = cloneDeep(data);
};

/**
 * Registered docs will be kept on re init
 */
export const registerDoc = <T>(id, collection: Collection, data: T) => {
  initialState[collection].push(data);
  addDoc(id, collection, data);
  return data;
};

export const initAll = () => {
  global.__data__ = global.__data__ || cloneDeep(initialState);
  initCollection("Activities", Object.values(DEMO_ACTIVITIES));
  initCollection(
    "ActivityUserProgresses",
    Object.values(DEMO_USER_ACTIVITIES).map((v, i) => ({ ...v.activityUserProgress, id: i }))
  );
  initCollection("Achievements", Object.values(DEMO_ACHIEVEMENTS_ALL));
  initCollection("AchievementsUser", Object.values(DEMO_ACHIEVEMENTS_USER));
  initCollection("SchoolUsers", Object.values(demoUsers));
  initCollection("MonitorRequests", Object.values(mockMonitorRequests));
  initCollection("Monitors", Object.values(mockServerMonitors));
  initCollection("SchoolEstablishments", Object.values(mockSchoolEstablishments));
  initCollection("SchoolsValidated", Object.values(mockSchools));
  initCollection("Schools", Object.values(mockSchools));
  global.__data__ = deepMerge(global.__data__, cloneDeep(initialState));
};

export const getLatestActivities = (): IActivityUser[] => {
  const activities = getDocs<IActivity>("Activities");
  const latestUserProgresses = getDocs<IActivityUserData>("ActivityUserProgresses")
    .sort((a, b) => (a.attemptNo < b.attemptNo ? 1 : -1))
    .filter((a, i, arr) => {
      const maxIndex = arr.findIndex((vv) => vv.activityId === a.activityId);
      return maxIndex === i;
    });
  return activities.map((a) => ({
    ...a,
    activityUserProgress: latestUserProgresses.find((v) => v.activityId === a.id),
  }));
};

export const getLatestActivity = (id): IActivityUser => {
  const activity = getDoc<IActivity>(id, "Activities");
  const activityUserProgress = getDocs<IActivityUserData>("ActivityUserProgresses")
    .filter((a) => a.activityId === id)
    .sort((a, b) => (a?.attemptNo < b?.attemptNo ? 1 : -1))[0];
  return {
    ...activity,
    activityUserProgress,
  };
};
