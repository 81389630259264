import { ILabelled } from "@reactdbclient/types.client.common";

export enum EAgeGroup {
  PreferNotToAnswer = 0,
  Between5And6 = 1,
  Between7And8 = 2,
  Between9And10 = 4,
  Between11And12 = 8,
  Between13And14 = 16,
  Between15And16 = 32,
  Between16And17 = 64,
  MoreThan18 = 128,
}

export const AgeGroups: Record<EAgeGroup, ILabelled> = {
  [EAgeGroup.PreferNotToAnswer]: {
    uid: EAgeGroup.PreferNotToAnswer,
    label: "I would prefer not to answer",
  },
  [EAgeGroup.Between5And6]: {
    uid: EAgeGroup.Between5And6,
    label: "5 to 6",
  },
  [EAgeGroup.Between7And8]: {
    uid: EAgeGroup.Between7And8,
    label: "7 to 8",
  },
  [EAgeGroup.Between9And10]: {
    uid: EAgeGroup.Between9And10,
    label: "9 to 10",
  },
  [EAgeGroup.Between11And12]: {
    uid: EAgeGroup.Between11And12,
    label: "11 to 12",
  },
  [EAgeGroup.Between13And14]: {
    uid: EAgeGroup.Between13And14,
    label: "13 to 14",
  },
  [EAgeGroup.Between15And16]: {
    uid: EAgeGroup.Between15And16,
    label: "15 to 16",
  },
  [EAgeGroup.Between16And17]: {
    uid: EAgeGroup.Between16And17,
    label: "16 to 17",
  },
  [EAgeGroup.MoreThan18]: {
    uid: EAgeGroup.MoreThan18,
    label: "18 or more",
  },
};
