import { FilterObjectClass } from "@reactdbclient/types.client.common";
import { getDataFetch, IApiResponse, postDataFetch, queryDetailedObjectToString, throwErrors } from "./apiHelpers";
import { SERVER_URL } from "../config";
import {
  TActivityId,
  IActivitySubmitData,
  IActivityUser,
  IActivityApiData,
  EActivityStatus,
  IActivityUserData,
  IActivityUserDataNotStarted,
  IMissingActivityData,
} from "../lib/Activities/IActivity";
import { IStepSubmission, TStepId, TStepState } from "../lib/Activities/IStep";
import { IAchievementUser } from "../lib/Achievements/IAchievement";
import { MonitorId } from "../lib/Monitor/IMonitor";
import { RoomId } from "../lib/School/IRoom";

export interface IApiGetActivitiesReturn extends IApiResponse {
  data: IActivityApiData[];
}
export interface IApiGetActivityReturn extends IApiResponse {
  data: IActivityApiData;
}

// TODO: Move this to logical location
export interface ICompleteActivityResponse {
  ok: boolean;
  data: {
    achievements: IAchievementUser[];
  };
}

export const apiGetActivities = async (filters: FilterObjectClass[], accessToken: string): Promise<IActivityUser[]> => {
  if (!accessToken) throw Error("Missing access token");
  const filterString = filters && queryDetailedObjectToString(filters);
  let url = encodeURI(`${SERVER_URL}/Activities/Summary`);
  const queryString = [filterString].filter((f) => f != null).join("&");
  if (queryString) url = url.concat("?").concat(queryString.toString());

  const headers = { Authorization: "Bearer " + accessToken };

  return getDataFetch<IApiGetActivitiesReturn>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => {
      const { data } = response.json;
      const dataCombinedA = data.map((act) => {
        const activityStatus = act.latestActivityUserProgress?.activityStatus || EActivityStatus.NOT_STARTED;
        const userProgress: IActivityUserData | IActivityUserDataNotStarted = {
          ...act.latestActivityUserProgress,
          isLocked: act.activityIsLocked,
          activityStatus,
        } as IActivityUserData | IActivityUserDataNotStarted;

        return {
          ...act.activity,
          activityUserProgress: userProgress,
        };
      });
      const dataWithPreviousActivities = dataCombinedA.map((act) => {
        // previous activities that have not been completed
        const previousActivities =
          act.previousActivityIds
            ?.map((a) => dataCombinedA.find((loadedActivity) => loadedActivity.id === a) || a)
            .filter((a) =>
              typeof a === "object" ? !(a?.activityUserProgress?.activityStatus === EActivityStatus.COMPLETED) : true
            )
            .map((a) => (typeof a === "object" ? a : ({ label: "Missing activity", id: a } as IMissingActivityData))) ||
          [];
        // full list of previous activities
        const previousActivitiesRequirement =
          act.previousActivityIds
            ?.map((a) => dataCombinedA.find((loadedActivity) => loadedActivity.id === a) || a)
            .map((a) =>
              typeof a === "object"
                ? a
                : ({ label: "Missing activity", id: a, missingData: true } as IMissingActivityData)
            ) || [];
        return {
          ...act,
          previousActivities,
          previousActivitiesRequirement,
        };
      });
      return dataWithPreviousActivities;
    });
};

export const apiGetActivity = async (id: TActivityId, accessToken: string): Promise<IActivityUser> => {
  const url = `${SERVER_URL}/Activities/${id}`;
  const headers = { Authorization: "Bearer " + accessToken };
  return getDataFetch<IApiGetActivityReturn>(url, "json", headers)
    .then(throwErrors)
    .then(({ response }) => {
      const activityStatus =
        response.json.data.latestActivityUserProgress?.activityStatus || EActivityStatus.NOT_STARTED;
      const userProgress: IActivityUserData | IActivityUserDataNotStarted = {
        ...response.json.data.latestActivityUserProgress,
        isLocked: response.json.data.activityIsLocked,
        activityStatus,
      } as IActivityUserData | IActivityUserDataNotStarted;
      return {
        ...response.json.data.activity,
        activityUserProgress: userProgress,
      };
    });
};

export const apiSubmitActivityStep = async (
  id: TActivityId,
  stepId: number,
  data: IStepSubmission<TStepState>,
  nextStep: TStepId = null,
  accessToken: string
) => {
  const url = `${SERVER_URL}/Activities/${id}/Steps/${stepId}`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<IActivitySubmitData, IApiResponse>(url, { data, nextStep }, headers).then(throwErrors);
};

export const apiGoToActivityStep = async (id: TActivityId, stepId: number, accessToken: string) => {
  const url = `${SERVER_URL}/Activities/${id}/ActiveStep/${stepId}`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<{}, IApiResponse>(url, {}, headers);
};

export const apiCompleteActivity = async (
  id: TActivityId,
  accessToken: string,
  completeCheatCode?: string
): Promise<ICompleteActivityResponse> => {
  const url = `${SERVER_URL}/Activities/${id}/Complete`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<{}, ICompleteActivityResponse>(url, { completeCheatCode: completeCheatCode }, headers)
    .then(throwErrors)
    .then(({ response }) => {
      return response.json;
    });
};

export const apiJumpActivity = async (id: TActivityId, accessToken: string): Promise<ICompleteActivityResponse> => {
  const url = `${SERVER_URL}/Activities/${id}/Jump`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<null, ICompleteActivityResponse>(url, null, headers)
    .then(throwErrors)
    .then(({ response }) => {
      return response.json;
    });
};

export const apiStartActivity = async (
  id: TActivityId,
  accessToken: string,
  monitorRefId?: MonitorId,
  roomId?: RoomId
) => {
  const url = `${SERVER_URL}/Activities/${id}/Start`;
  const headers = { Authorization: "Bearer " + accessToken };
  return postDataFetch<{}, IApiResponse>(url, { monitorReferenceId: monitorRefId, roomId: roomId }, headers);
};

export const apiActivities = {
  get: apiGetActivity,
  getAll: apiGetActivities,
  submitStep: apiSubmitActivityStep,
  goToStep: apiGoToActivityStep,
  startActivity: apiStartActivity,
  completeActivity: apiCompleteActivity,
  jumpActivity: apiJumpActivity,
};
