import React from "react";
import { Uid } from "@reactdbclient/types.client.common";
import { keywordMap } from "../../../../dummyData/keywords";
import { IStepProps } from "../../../../lib/Activities/IStep";
import { IMultiCheckboxOption } from "../../../../lib/Forms/IMultiCheckboxOption";
import { StepDescription } from "../../../../styles/app";
import { CTAButtonStyle } from "../../../../styles/button";
import { MultiCheckboxB } from "../../../FormComponents/MultiCheckbox";
import { TextFormatter } from "../../../TextFormatter";
import { getNextStep } from "../../nextStepUtils";

export interface IMultiCheckboxStepOptions extends IMultiCheckboxOption {
  isCorrect: boolean;
  responseMessage: string;
}

export interface IQuizMultiChoiceExtraProps {
  options: IMultiCheckboxStepOptions[];
  multiple?: boolean;
}

export interface IQuizMultiChoiceState {
  current: {
    date: Date;
    selection: boolean[];
    selectionIds: Uid[];
  };
  selectionHistory: {
    date: Date;
    selection: boolean[];
    selectionIds: Uid[];
  }[];
}

export const QuizMultiChoiceStep = (props: IStepProps<IQuizMultiChoiceExtraProps, IQuizMultiChoiceState>) => {
  const {
    state,
    description,
    additionalProps,
    onChange,
    onSubmit,
    optional,
    nextSteps,
    previousStepsState,
    activitySteps,
  } = props;
  const { options, multiple } = additionalProps;
  const submit = () => {
    if (!optional) {
      if (!state || !options.find((opt, i) => state?.current?.selection[i])) {
        return;
      }
    }

    const nextStepRef = getNextStep(null, nextSteps, previousStepsState, activitySteps, props, {
      ...props,
      state,
    });
    onSubmit({ newValue: state, nextStep: nextStepRef });
  };

  const onSelect = (v) => {
    onChange("selectionHistory", [...(state?.selectionHistory || []), ...(state?.current ? [state.current] : [])]);
    onChange("current", {
      date: new Date(),
      selection: v,
      selectionIds: options.filter((opt, i) => v[i]).map((opt) => opt.uid),
    });
  };

  const selectedOption = state?.current?.selectionIds && options.find((o) => o.uid === state?.current?.selectionIds[0]);
  const message = selectedOption?.responseMessage;
  const isCorrect = selectedOption?.isCorrect;
  return (
    <form
      style={{ width: "100%" }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <StepDescription>
        <TextFormatter text={description} keywordMap={keywordMap} />
      </StepDescription>
      <MultiCheckboxB
        name="selection"
        options={options}
        value={state?.current?.selection || options?.map(() => false)}
        onChange={onSelect}
        multiple={multiple}
        direction="column"
      />
      <TextFormatter text={message || ""} keywordMap={keywordMap} />
      {isCorrect && (
        <CTAButtonStyle type="button" onClick={submit}>
          Next
        </CTAButtonStyle>
      )}
    </form>
  );
};
