// TODO: Setup up these endpoints on the server
import { FilterObjectClass } from "@reactdbclient/types.client.common";
import { getDataFetch, queryDetailedObjectToString, throwErrors } from "./apiHelpers";
import { SERVER_URL } from "../config";
import { IDataView, IDataViewFull, DataViewId, TDataAdditionalProps } from "../lib/DataViews/IDataView";

export interface IApiGetDataViewsReturn {
  data: IDataView[];
}

export interface IApiGetDataViewReturn<AdditionalProps extends TDataAdditionalProps> {
  data: IDataViewFull<AdditionalProps>;
}

export const apiGetDataViews = async (filters?: FilterObjectClass[]) => {
  const filterString = filters && queryDetailedObjectToString(filters);
  let url = encodeURI(`${SERVER_URL}/DataViews/Summary`);
  const queryString = [filterString].filter((f) => f != null).join("&");
  if (queryString) url = url.concat("?").concat(queryString.toString());
  return getDataFetch<IApiGetDataViewsReturn>(url)
    .then(throwErrors)
    .then(({ response }) => {
      return response.json.data;
    });
};

export const apiGetDataView = async <AdditionalProps extends TDataAdditionalProps>(id: DataViewId) => {
  const url = `${SERVER_URL}/DataViews/${id}`;
  return getDataFetch<IApiGetDataViewReturn<AdditionalProps>>(url)
    .then(throwErrors)
    .then(({ response }) => {
      return response.json.data;
    });
};

export const apiDataViews = {
  get: apiGetDataView,
  getAll: apiGetDataViews,
};
