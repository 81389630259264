import { Uid } from "@reactdbclient/types.client.common";
import { ImageUrl } from "../Primatives/Paths";
import { UserGroup } from "../User/UserGroup";

export type TResourceId = Uid;

export enum EResourceType {
  DOWNLOAD = 1,
  CERTIFICATE = 2,
  POPUP_INFO = 3,
  PLAIN_TEXT = 4,
}

export interface IAppResource {
  id: TResourceId;
  label: string;
  resourceType: EResourceType;
  description: string;
  resourceUrl: string;
  logo?: ImageUrl;
  thumbnailUrl?: ImageUrl;
  restrictedAccessGroups?: UserGroup[];
}
