import { EChartsOption, XAXisComponentOption } from "echarts";
import ReactECharts from "echarts-for-react";
import styled, { useTheme } from "styled-components";
import {
  TDateFormatter,
  dateLabels,
  IDateFormatOptions,
  dateFormatters,
  ETimeFormat,
} from "../../../lib/DataViews/ETimeFormats";
import { IMonitorDataItem, IMonitorDataItemProcessed } from "../../../lib/MonitorData/IMonitorData";
import { accentColors } from "../../../styles/themes";
import { EBaseLineStandardTypes, IBaseLineStandard } from "../lib";
import { IPreProcessDataOptions, preProcessData } from "../dataProcessing/dataProcessing";
import { defaultTooltipFormatter } from "../common/TooltipFormatters";
import { EAggregationInterval } from "../../../lib/MonitorData/AggregationIntervals";
import { EDayRange } from "../../DateRangePicker/lib";

export interface ITooltipOptions {
  tooltipFormatter: (yLabel: string, dateFormatter: TDateFormatter, decimalPlaces: number) => (params) => string;
  fixedPopup?: boolean;
  decimalPlaces?: number;
}
export interface ISimplePlotProps {
  data: IMonitorDataItem<number>[];
  showAverage?: boolean;
  showMin?: boolean;
  showMax?: boolean;
  yLabel: string;
  timeFormat: EAggregationInterval | ETimeFormat | EDayRange;
  fontSize?: string | number;
  baselineStandards?: IBaseLineStandard[];
  allowScale?: boolean;
  yRange?: [number?, number?];
  preProcessDataOptions?: IPreProcessDataOptions;
  dateFormatOptions?: IDateFormatOptions;
  tooltipOptions?: ITooltipOptions;
  style?: React.CSSProperties;
}

export const ContainerStyle = styled.div`
  height: auto;
  .reactECharts-container {
    aspect-ratio: 1/1;
    height: 16rem;
    @media only screen and (min-width: ${({ theme }) => theme.mediaBoundaries.medium}) {
      height: 24rem;
      min-height: 24rem;
    }
  }
`;

export const constructAxis = (
  xAxisName: string,
  fontSize: string | number,
  dateFormatter: TDateFormatter
): XAXisComponentOption => {
  return {
    type: "time",
    axisLabel: {
      margin: 7,
      formatter: dateFormatter,
      hideOverlap: true,
    },
    name: xAxisName,
    nameLocation: "middle",
    nameGap: dateFormatter(Date.now()).split("\n").length * 15 + 4,
    nameTextStyle: {
      fontSize,
      fontWeight: "bold",
    },
  };
};

const LEFT_PAD = 20;
const LEFT_PAD_TEXT_PAD = 22;
const LEFT_PAD_TEXT_SCALE = 8;

export const SimplePlot = ({
  data,
  yLabel,
  timeFormat,
  fontSize = "1rem",
  baselineStandards = [],
  allowScale = false,
  showAverage = false,
  showMin = false,
  showMax = false,
  yRange,
  preProcessDataOptions = {},
  dateFormatOptions = {},
  tooltipOptions = { tooltipFormatter: defaultTooltipFormatter, decimalPlaces: 0 },
  style = {},
}: ISimplePlotProps) => {
  const dateFormatter = dateFormatters[timeFormat](dateFormatOptions);
  const xAxisName = dateLabels[timeFormat];
  const theme = useTheme();
  const dataProcessed = preProcessData(data, preProcessDataOptions);
  if (!dataProcessed || dataProcessed.length === 0) return <p>Missing data!</p>;

  function focusedHoursFilter(item: IMonitorDataItemProcessed<number>) {
    return item.highlighted === undefined || item.highlighted;
  }

  const chartOptions: EChartsOption = {
    responsive: true,
    tooltip: {
      trigger: "axis",
      formatter: tooltipOptions.tooltipFormatter(
        yLabel,
        dateFormatters[timeFormat](dateFormatOptions),
        tooltipOptions.decimalPlaces
      ),
      position: tooltipOptions.fixedPopup ? [10, "30%"] : "left",
    },
    dataZoom: {
      show: allowScale,
      backgroundColor: "rgba(255,255,255,1)",
      labelFormatter: dateFormatter,
      dataBackground: {
        areaStyle: {
          color: theme.colors.mainLighter,
        },
      },
      selectedDataBackground: {
        areaStyle: {
          color: theme.colors.main,
        },
      },
      fillerColor: "rgba(200,200,200,0.5)",
      borderColor: "rgba(200,200,200,0.5)",
      handleStyle: {
        color: "rgba(255,255,255,0.5)",
        borderColor: "rgba(100,100,100,0.8)",
      },
      brushStyle: {
        color: "rgba(255,255,255,0.5)",
        borderColor: "rgba(100,100,100,0.8)",
      },
      moveHandleStyle: {
        color: "rgba(255,255,255,0.5)",
        borderColor: "rgba(100,100,100,0.8)",
      },
      emphasis: {
        handleStyle: {
          color: "rgba(255,255,255,0.5)",
          borderColor: "rgba(100,100,100,0.8)",
        },
        moveHandleStyle: {
          color: "rgba(190,190,190,0.8)",
          borderColor: "rgba(100,100,100,0.8)",
        },
        handleLabel: {},
      },
    },
    grid: {
      top: showMax ? 30 : 15,
      left:
        LEFT_PAD_TEXT_PAD +
        LEFT_PAD +
        Math.max(...dataProcessed.map((d) => d.value)).toString().length * LEFT_PAD_TEXT_SCALE, // Hack to stop text overlapping y values,
      right:
        baselineStandards && baselineStandards.length > 0
          ? Math.max(...baselineStandards.map((baselineStandard) => baselineStandard.label.length)) * 7.3
          : 10,
      bottom: dateFormatter(Date.now()).split("\n").length * 15 + (allowScale ? 70 : 20),
    },
    xAxis: constructAxis(xAxisName, fontSize, dateFormatter),
    yAxis: {
      type: "value",
      axisLabel: {
        fontSize,
      },
      name: yLabel,
      nameLocation: "middle",
      nameGap:
        LEFT_PAD_TEXT_PAD + Math.max(...dataProcessed.map((d) => d.value)).toString().length * LEFT_PAD_TEXT_SCALE, // Hack to stop text overlapping y values
      nameTextStyle: {
        fontSize,
        fontWeight: "bold",
      },
      scale: true,
      min: yRange ? yRange[0] : null,
      max: yRange && yRange[1],
      boundaryGap: ["10%", showMax ? "18%" : "10%"],
    },
    series: [
      {
        name: "School Hours",
        type: "line",
        data: dataProcessed.filter(focusedHoursFilter).map((d) => [d.dateStamp.valueOf(), d.value]),
        color: theme.colors.text,
        lineStyle: {
          opacity: 0,
        },
        symbolSize: 10,
        markPoint: {
          data: [
            ...(showMin
              ? [
                  {
                    type: "min" as "min",
                    name: "Min",
                    label: {
                      position: "inside" as "inside",
                      formatter: function (params) {
                        return params.name;
                      },
                    },
                  },
                ]
              : []),
            ...(showMax
              ? [
                  {
                    type: "max" as "max",
                    name: "Max",
                    label: {
                      position: "inside" as "inside",
                      formatter: function (params) {
                        return params.name;
                      },
                    },
                  },
                ]
              : []),
          ],
        },
      },
      {
        name: "All Hours",
        smooth: true,
        smoothMonotone: "x",
        data: dataProcessed.map((d) => [d.dateStamp.valueOf(), d.value]),
        type: "line",
        symbolSize: 10,
        lineStyle: {
          // NOTE: Use text color as this is setup for correct contrast ratio
          color: theme.colors.text,
          width: 2,
          type: "dashed",
        },
        itemStyle: {
          color: theme.colors.text,
          opacity: 0,
        },
        markArea: {
          data: [
            ...(baselineStandards
              ? baselineStandards
                  .filter((bs) => bs.type === EBaseLineStandardTypes.AREA)
                  .map(
                    (baselineStandard, i) =>
                      [
                        {
                          itemStyle: {
                            color: baselineStandard.color || accentColors[i],
                            opacity: 0.4,
                          },
                          label: {
                            formatter: baselineStandard.label,
                            position: "right",
                          },
                          coord: [dataProcessed[0].dateStamp.valueOf(), baselineStandard.value],
                        },
                        {
                          coord: [dataProcessed[dataProcessed.length - 1].dateStamp.valueOf(), baselineStandard.upper],
                        },
                      ] as [any, any]
                  )
              : []),
          ],
          silent: true,
        },
        markLine: {
          symbol: "none",
          data: [
            ...(showAverage
              ? [
                  {
                    type: "average",
                    label: {
                      position: "insideMiddle",
                      formatter: (v) => `Average: ${v.value}`,
                      backgroundColor: "white",
                      padding: 2,
                    },
                    lineStyle: {
                      width: 1,
                      color: "#000",
                    },
                  },
                ]
              : []),

            ...(baselineStandards
              ? (baselineStandards
                  .filter((bs) => bs.type === EBaseLineStandardTypes.LINE)
                  .map((baselineStandard) => ({
                    symbol: "none",
                    label: {
                      formatter: baselineStandard.label,
                      backgroundColor: "white",
                      position: "insideMiddle",
                    },
                    lineStyle: {
                      width: 1,
                      color: "#000",
                    },
                    yAxis: baselineStandard.value,
                  })) as [any])
              : []),
          ],
        },
      },
    ],
  };
  return (
    <ContainerStyle data-testid="reactECharts-containerOuter">
      <ReactECharts
        className="reactECharts-container"
        key={Date.now()}
        option={chartOptions}
        style={{ width: "100%", ...style }}
      />
    </ContainerStyle>
  );
};
