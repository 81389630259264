import React from "react";
import { AuthContext } from "react-oauth2-code-pkce";
import { FilterObjectClass, Uid } from "@reactdbclient/types.client.common";
import { IActivityCardData } from "./IActivityCardProps";

import { useAppAuthDispatch, useAppDispatch, useAppSelector } from "../../Redux/hooks";
import {
  reduxCompleteActivity,
  reduxGoToActivityStep,
  reduxRestartActivity,
  reduxStartActivity,
  reduxSubmitActivityStep,
  actions as activitySliceActions,
  EActivityManagerStatus,
  ActivitiesError,
} from "../../Redux/Activities/activitiesReducer";
import { EActivityStatus, IActivityUser, TActivityId } from "../../lib/Activities/IActivity";
import { IStepSubmission, TStepId, TStepState } from "../../lib/Activities/IStep";
import { getImageUrl } from "../../utils/url_utils";
import { UserGroup } from "../../lib/User/UserGroup";
import { MonitorId } from "../../lib/Monitor/IMonitor";
import { RoomId } from "../../lib/School/IRoom";
import { ApiContext } from "../../Redux/Api/ApiContext";

export interface IQueueItem<IdType> {
  uid: IdType;
  args: Array<any>;
}

export interface IUseGetActivityViewMetaDataProps {
  filters?: FilterObjectClass[];
  groupId: string;
  userRole: UserGroup;
}

const checkIfLocked = (activity: IActivityUser, userRole: UserGroup) => {
  if (activity.targetRoles?.indexOf(userRole) === -1) {
    return true;
  }
  if (activity.activityUserProgress?.isLocked && userRole === UserGroup.STUDENT) {
    return true;
  }
  return false;
};

export const useWrappedActivity = (activity: IActivityUser, uniqueCardId: Uid): IActivityCardData => {
  console.warn("DEPRECATED!");
  const dispatchAuth = useAppAuthDispatch();
  const { token } = React.useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { apiGetActivities } = React.useContext(ApiContext);
  const loadedActivities = useAppSelector((state) => state.activitiesState.userActivities);
  const loadedActivityData = loadedActivities.find((a) => a.id === activity.id);
  const loadAttempts = React.useRef(0);

  React.useEffect(() => {
    if (!loadedActivities?.length && loadAttempts.current < 10) {
      loadAttempts.current += 1;
      dispatch(activitySliceActions.setStatus(EActivityManagerStatus.LOADING_ACTIVITIES));

      apiGetActivities(undefined, token)
        .then((activitiesData) => {
          dispatch(activitySliceActions.resetUserActivities(activitiesData));
          dispatch(activitySliceActions.setStatus(EActivityManagerStatus.IDLE));
        })
        .catch(() => {
          // TODO: Handle error
        });
    }
  }, [dispatch, loadedActivities, apiGetActivities, token]);

  return {
    ...activity,
    ...loadedActivityData,
    uniqueCardId,
    title: activity.label,
    logo: getImageUrl(activity.logo),
    totalSteps: activity.activitySteps?.length || 0,
    submitStep: (
      activityId: TActivityId,
      stepId: number,
      submittedData: IStepSubmission<TStepState>,
      nextStep?: TStepId
    ) => dispatchAuth(reduxSubmitActivityStep, activityId, stepId, submittedData, nextStep),
    goToStep: (activityId: TActivityId, stepId: number) => dispatchAuth(reduxGoToActivityStep, activityId, stepId),
    completeActivity: (activityId: TActivityId, completeCheatCode?: string) =>
      dispatchAuth(reduxCompleteActivity, activityId, completeCheatCode),
    startActivity: (activityId: TActivityId, monitorRefId?: MonitorId, roomId?: RoomId) =>
      dispatchAuth(reduxStartActivity, activityId, monitorRefId, roomId),
    restartActivity: (activityId: TActivityId, monitorRefId?: MonitorId, roomId?: RoomId) =>
      dispatchAuth(reduxRestartActivity, activityId, monitorRefId, roomId),
    getFullActivityData: () => {}, // TODO: Implement this if required by server
    previousActivities:
      activity.previousActivityIds?.map((a) => loadedActivities.find((loadedActivity) => loadedActivity.id === a)) ||
      [],
    activityUserProgress: activity.activityUserProgress || {
      activityId: activity.id,
      activityStatus: EActivityStatus.NOT_STARTED,
    },
  };
};

export const useGetSingleActivityMetaData = ({
  activityId,
  userRole,
}): IActivityCardData | { loading: true } | { loading: false; error: string } => {
  const dispatchAuth = useAppAuthDispatch();
  const { token } = React.useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { apiGetActivities } = React.useContext(ApiContext);
  const firstLoad = React.useRef(true);
  const [localLoading, setLocalLoading] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState<string | null>(null);

  const activityData = useAppSelector((state) =>
    state.activitiesState.userActivities.find((a) => String(a.id) === activityId)
  );

  React.useEffect(() => {
    if (firstLoad.current || (!localLoading && !errorLoading)) {
      firstLoad.current = false;
      if (activityData) return;
      setLocalLoading(true);
      setErrorLoading(null);
      dispatch(activitySliceActions.setStatus(EActivityManagerStatus.LOADING_ACTIVITIES));
      apiGetActivities(undefined, token)
        .then((activitiesData) => {
          const activityDoesNotExist = !activitiesData.find((a) => String(a.id) === activityId);
          if (activityDoesNotExist) setErrorLoading("Activity does not exist!");
          dispatch(activitySliceActions.resetUserActivities(activitiesData));
        })
        .catch(() => {
          dispatch(activitySliceActions.setError(new ActivitiesError("Failed to load activity data")));
          setErrorLoading("Failed to load activity data");
        })
        .finally(() => {
          dispatch(activitySliceActions.setStatus(EActivityManagerStatus.IDLE));
          setLocalLoading(false);
        });
    }
  }, [dispatch, apiGetActivities, token, activityData, localLoading, errorLoading, activityId]);

  const isReady = React.useMemo(() => {
    return activityData ? true : false;
  }, [activityData]);

  const submitStep = React.useCallback(
    (activityId: TActivityId, stepId: number, submittedData: IStepSubmission<TStepState>, nextStep?: TStepId) =>
      dispatchAuth(reduxSubmitActivityStep, activityId, stepId, submittedData, nextStep),
    [dispatchAuth]
  );

  const completeActivity = React.useCallback(
    (activityId: TActivityId, completeCheatCode?: string) =>
      dispatchAuth(reduxCompleteActivity, activityId, completeCheatCode),
    [dispatchAuth]
  );

  if (errorLoading)
    return {
      loading: false,
      error: errorLoading,
    };

  if (!isReady)
    return {
      loading: true,
    };

  return {
    ...activityData,
    uniqueCardId: activityId,
    title: activityData.label,
    logo: getImageUrl(activityData.logo),
    totalSteps: activityData.activitySteps?.length || 0,
    submitStep,
    goToStep: (activityId: TActivityId, stepId: number) => dispatchAuth(reduxGoToActivityStep, activityId, stepId),
    completeActivity,
    startActivity: (activityId: TActivityId, monitorRefId?: MonitorId, roomId?: RoomId) =>
      dispatchAuth(reduxStartActivity, activityId, monitorRefId, roomId),
    restartActivity: (activityId: TActivityId, monitorRefId?: MonitorId, roomId?: RoomId) =>
      dispatchAuth(reduxRestartActivity, activityId, monitorRefId, roomId),
    getFullActivityData: () => {}, // TODO: Implement this if required by server
    activityUserProgress: {
      ...(activityData.activityUserProgress || {
        activityId: activityData.id,
        activityStatus: EActivityStatus.NOT_STARTED,
      }),
      isLocked: checkIfLocked(activityData, userRole),
    },
  };
};

export const useLoadActivitiesStart = ({ filters }) => {
  const { token } = React.useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { apiGetActivities } = React.useContext(ApiContext);

  const firstLoad = React.useRef(true);
  const [localLoading, setLocalLoading] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState<string | null>(null);
  const [successLoading, setSuccessLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (successLoading) return;
    if (firstLoad.current || (!localLoading && !errorLoading)) {
      firstLoad.current = false;
      setErrorLoading(null);
      setLocalLoading(true);
      dispatch(activitySliceActions.setStatus(EActivityManagerStatus.LOADING_ACTIVITIES));
      apiGetActivities(filters, token)
        .then((activitiesData) => {
          setSuccessLoading(true);
          dispatch(activitySliceActions.resetUserActivities(activitiesData));
        })
        .catch(() => {
          dispatch(activitySliceActions.setError(new ActivitiesError("Failed to load activity data")));
          setErrorLoading("Failed to load activity data");
        })
        .finally(() => {
          dispatch(activitySliceActions.setStatus(EActivityManagerStatus.IDLE));
          setLocalLoading(false);
        });
    }
  }, [dispatch, apiGetActivities, token, localLoading, errorLoading, filters, successLoading]);
};

export const useGetActivityMetaData = ({ filters, groupId, userRole }: IUseGetActivityViewMetaDataProps) => {
  const dispatchAuth = useAppAuthDispatch();
  const { token } = React.useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { apiGetActivities } = React.useContext(ApiContext);
  const firstLoad = React.useRef(true);
  const [localLoading, setLocalLoading] = React.useState(false);
  const [errorLoading, setErrorLoading] = React.useState<string | null>(null);
  const [successLoading, setSuccessLoading] = React.useState<boolean>(false);

  const loadedActivities = useAppSelector((state) => state.activitiesState.userActivities);

  React.useEffect(() => {
    setSuccessLoading(false);
  }, [filters, groupId, userRole]);

  React.useEffect(() => {
    if (successLoading) return;
    if (firstLoad.current || (!localLoading && !errorLoading)) {
      firstLoad.current = false;
      setErrorLoading(null);
      setLocalLoading(true);
      dispatch(activitySliceActions.setStatus(EActivityManagerStatus.LOADING_ACTIVITIES));
      apiGetActivities(filters, token)
        .then((activitiesData) => {
          setSuccessLoading(true);
          dispatch(activitySliceActions.resetUserActivities(activitiesData));
        })
        .catch(() => {
          dispatch(activitySliceActions.setError(new ActivitiesError("Failed to load activity data")));
          setErrorLoading("Failed to load activity data");
        })
        .finally(() => {
          dispatch(activitySliceActions.setStatus(EActivityManagerStatus.IDLE));
          setLocalLoading(false);
        });
    }
  }, [dispatch, apiGetActivities, token, localLoading, errorLoading, filters, successLoading]);

  /* We need to parse activity from DB into the Activity props here */
  const cards: IActivityCardData[] = React.useMemo(
    () =>
      loadedActivities.map((activity) => ({
        ...activity,
        uniqueCardId: `card_id_${groupId}_${activity.id}`,
        title: activity.label,
        logo: getImageUrl(activity.logo),
        totalSteps: activity.activitySteps?.length || 0,
        submitStep: (
          activityId: TActivityId,
          stepId: number,
          submittedData: IStepSubmission<TStepState>,
          nextStep?: TStepId
        ) => dispatchAuth(reduxSubmitActivityStep, activityId, stepId, submittedData, nextStep),
        goToStep: (activityId: TActivityId, stepId: number) => dispatchAuth(reduxGoToActivityStep, activityId, stepId),
        completeActivity: (activityId: TActivityId, completeCheatCode?: string) =>
          dispatchAuth(reduxCompleteActivity, activityId, completeCheatCode),
        startActivity: (activityId: TActivityId, monitorRefId?: MonitorId, roomId?: RoomId) =>
          dispatchAuth(reduxStartActivity, activityId, monitorRefId, roomId),
        restartActivity: (activityId: TActivityId, monitorRefId?: MonitorId, roomId?: RoomId) =>
          dispatchAuth(reduxRestartActivity, activityId, monitorRefId, roomId),
        getFullActivityData: () => {}, // TODO: Implement this if required by server
        hidden: userRole === UserGroup.STUDENT && activity.targetRoles?.indexOf(userRole) === -1,
        previousActivities:
          activity.previousActivityIds?.map((a) =>
            loadedActivities.find((loadedActivity) => loadedActivity.id === a)
          ) || [],
        activityUserProgress: {
          ...(activity.activityUserProgress || {
            activityId: activity.id,
            activityStatus: EActivityStatus.NOT_STARTED,
          }),
          isLocked: checkIfLocked(activity, userRole),
        },
      })) || [],
    [loadedActivities, groupId, dispatchAuth, userRole]
  );

  return { cards, loading: localLoading, error: errorLoading };
};
