import React from "react";
import { useFormContext, useController, ValidateResult, Validate } from "react-hook-form";
import {
  ISearchAndSelectProps,
  SearchAndSelect,
  TSearchAndSelectSearchFunction,
} from "@react_db_client/components.search-and-select-v2";
import { InputStyle } from "../../styles/form";
import { Label } from "./Label";
import { ErrorMessage } from "./ErrorMessage";
import { IDocument } from "@reactdbclient/types.client.common";

export interface ISelectOption {
  uid: number | string;
  label: string;
}

export interface IInputProps<Item extends IDocument>
  extends Omit<ISearchAndSelectProps<Item>, "searchFunction" | "handleSelect"> {
  type?: "select";
  name: string;
  label?: string;
  required?: boolean;
  validate?: Record<string, Validate<any, any>> | ((value: any) => ValidateResult | Promise<ValidateResult>);
  allowInputOnly?: boolean;
  searchFunction?: TSearchAndSelectSearchFunction<Item>;
  options?: ISelectOption[];
  validateOnChange?: boolean;
  allowMultiple?: boolean;
  showSearchField?: boolean;
}

export interface IItem {
  uid: string | number;
}

const validateSelect = (name, options, required) => (v) => {
  if (!required && !v) return true;
  if (required && !v) return `${name} is undefined`;
  if (options.find((o) => o.uid === v.uid || o.uid === v || o.label === v)) return true;
  return `${v} is not a valid selection.`;
};

const defaultSearchFunc = (options) => async () => options;

export const SelectAdvanced = <ResultType extends IDocument>(props: IInputProps<ResultType>): JSX.Element => {
  const {
    type,
    label,
    name,
    required,
    options,
    validateOnChange,
    searchFunction,
    validate,
    allowInputOnly,
    availableFilters,
    previewHeadings,
    headings,
    ...inputProps
  } = props;
  const { control, trigger } = useFormContext();

  if (type && type !== "select") throw Error(`Attempted to use select input for none select type: ${type}`);
  if (!name) throw Error("Must supply name");

  const validateOverride = React.useMemo(
    () => validate || validateSelect(label, options, required),
    [validate, label, options, required]
  );

  const searchFunctionOverride = React.useMemo(
    () => searchFunction || defaultSearchFunc(options),
    [searchFunction, options]
  );

  const {
    field: { onChange },
  } = useController({
    name,
    control,
    rules: { required: required ? "Required" : undefined, validate: validateOverride },
  });

  // const onBlurMiddleware = () => {
  //   onBlur();
  //   if (validateOnChange) trigger(name);
  // };

  const onChangeMiddleware = (selectedData) => {
    const newVal = allowInputOnly ? selectedData || name : selectedData || "";
    onChange({ target: { value: newVal } });
    if (validateOnChange) trigger(name);
  };

  return (
    <InputStyle>
      <Label name={name} label={label} required={required} />
      <SearchAndSelect<ResultType>
        searchFunction={searchFunctionOverride}
        availableFilters={availableFilters}
        autoUpdate
        headings={headings}
        previewHeadings={previewHeadings}
        handleSelect={onChangeMiddleware}
        // liveUpdate
        autoWidth
        allowSelectionPreview
        id={name}
        {...inputProps}
      />

      <ErrorMessage name={name} />
    </InputStyle>
  );
};
