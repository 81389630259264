import { EComparisons } from "@reactdbclient/types.client.common";
import { TvocsAndPmsInfoContent } from "../components/Documents/TvocsAndPms";
import { AchievementCategories, EAchievementCategory } from "../lib/Achievements/EAchievementCategory";
import { EGrades } from "../lib/Activities/EGrades";
import { EThemes } from "../lib/Activities/EThemes";
import {
  EActivityStatus,
  EActivityTypes,
  IActivity,
  IActivityUser,
  IActivityUserData,
  IActivityUserStarted,
} from "../lib/Activities/IActivity";
import { IDataDetectivesActivity, IDataDetectivesStep } from "../lib/Activities/IDataDetectives";
import { EStepTypes } from "../lib/Activities/IStep";
import { getNodeText } from "../utils/textUtils";
import { exampleTags } from "./tags";

export const exampleDataDetectiveSteps: IDataDetectivesStep<any>[] = [
  {
    id: 1,
    stepRef: "step1",
    label: "Step 1",
    description: `Step 1 description [link example](/resources/ventilation) !list of TVOCs sources!`,
    type: EStepTypes.MULTIPLE_CHOICE,
    group: 1,
    nextSteps: [{ stepRef: "step2" }, { stepRef: "step3" }, { stepRef: "step4" }],
    additionalProps: {
      options: [
        {
          uid: 2,
          targetStep: "step2",
          label: "I want to go to step 2",
        },
        {
          uid: 3,
          targetStep: "step3",
          label: "I want to go to step 3",
        },
        {
          uid: 4,
          targetStep: "step4",
          label: "I want to go to step 4",
        },
      ],
    },
  },
  {
    id: 2,
    stepRef: "step2",
    label: "Step 2",
    description: `Step 2 description`,
    type: EStepTypes.MULTIPLE_CHOICE,
    group: 2,
    nextSteps: [{ stepRef: "step3" }],
    additionalProps: {
      options: [
        {
          uid: 3,
          targetStep: "step3",
          label: "I want to go to step 3",
        },
      ],
    },
  },
  {
    id: 3,
    stepRef: "step3",
    label: "Step 3",
    description: `Step 3 description`,
    type: EStepTypes.MULTIPLE_CHOICE,
    group: 2,
    nextSteps: [{ stepRef: "step4" }],
    additionalProps: {
      options: [
        {
          uid: 4,
          targetStep: "step4",
          label: "I want to go to step 4",
        },
      ],
    },
  },
  {
    id: 4,
    stepRef: "step4",
    label: "Step 4",
    nextSteps: [],
    isLastStep: true,
    description: `Step 4 description`,
    type: EStepTypes.TEXT_INPUT,
    group: 3,
  },
];

export const dataDetectivesActivityBase: IDataDetectivesActivity = {
  id: "dataDetectivesActivity",
  label: "Data Detectives",
  logo: "dataDetectivesActivity.jpg",
  longDescription: `This is a long description of the activity...

It can span multiple lines.

# And use headings etc.

**and any other markdown functionality**
!guidance on interpreting monitor readings!

${getNodeText(TvocsAndPmsInfoContent)}
`,
  description: `This is a **short** description of the activity...

It can span multiple lines.
`,
  imageUrl: "/images/app/activities/icons/ANALYSIS_SMALL.png",
  type: EActivityTypes.DATA_DETECTIVES,
  noOfPeople: "1",
  targetGrade: [EGrades.LVL1],
  timeEstimates: {
    investigation: 14,
    video: 5,
    discussion: 11,
  },
  theme: EThemes.B,
  activitySteps: exampleDataDetectiveSteps,
  tags: [exampleTags.co2],
  activityScores: [
    {
      category: AchievementCategories[EAchievementCategory.QUIZ],
      score: 3,
    },
    {
      category: AchievementCategories[EAchievementCategory.TOP_RESPONDANT],
      score: 2,
    },
    {
      category: AchievementCategories[EAchievementCategory.TEAM_STUDENT],
      score: 8,
    },
    {
      category: AchievementCategories[EAchievementCategory.CHIEF_INVESTIGATOR],
      score: 9,
    },
    {
      category: AchievementCategories[EAchievementCategory.CONTENT_CREATOR],
      score: 4,
    },
    {
      category: AchievementCategories[EAchievementCategory.EXPERIMENT_WHIZZ],
      score: 1,
    },
    {
      category: AchievementCategories[EAchievementCategory.DATA_ANALYST],
      score: 1,
    },
    {
      category: AchievementCategories[EAchievementCategory.HERO],
      score: 7,
    },
  ],
  nextActivityIds: [],
  previousActivityIds: [],
};

export const dataDetectivesActivity: IDataDetectivesActivity = {
  ...dataDetectivesActivityBase,
};

export const exampleDataDetectiveStepsWithDependencies: IDataDetectivesStep<any>[] = [
  {
    id: 1,
    stepRef: "step1",
    label: "Step 1",
    description: `Remember this option`,
    type: EStepTypes.MULTIPLE_CHOICE,
    group: 1,
    nextSteps: [
      { stepRef: "step2" },
      {
        id: "usethis",
        stepRef: "step5",
        dependencies: [{ id: "this", targetField: "$this", targetValue: "this", comparison: EComparisons.EQUALS }],
      },
    ],
    additionalProps: {
      // We reference this answer later on
      options: [
        {
          uid: "foo",
          label: "I like foo",
        },
        {
          uid: "bar",
          label: "I like bar",
        },
        {
          uid: "this",
          label: "I like this",
          targetStep: "usethis",
        },
      ],
    },
  },
  {
    id: 2,
    stepRef: "step2",
    label: "Step 2",
    description: `Step 2 description`,
    type: EStepTypes.INFO,
    group: 2,
    nextSteps: [
      {
        id: "step3",
        stepRef: "step3",
        dependencies: [
          { id: "selectedFoo", targetField: "step1", targetValue: "foo", comparison: EComparisons.EQUALS },
        ],
      },
      { stepRef: "step4" },
    ],
    additionalProps: {
      content: "Your next step will be based on the answer to step 1",
    },
  },
  {
    id: 3,
    stepRef: "step3",
    label: "Hey you like foo!",
    nextSteps: [],
    isLastStep: true,
    description: `Step 3 description`,
    type: EStepTypes.INFO,
    group: 3,
  },
  {
    id: 4,
    stepRef: "step4",
    label: "Hey you like bar!",
    nextSteps: [],
    isLastStep: true,
    description: `Step 4 description`,
    type: EStepTypes.INFO,
    group: 3,
  },
  {
    id: 5,
    stepRef: "step5",
    label: "Hey you like $this!",
    nextSteps: [],
    isLastStep: true,
    description: `Step 5 description`,
    type: EStepTypes.INFO,
    group: 3,
  },
];

export const dataDetectivesActivityCheckDependencies = {
  ...dataDetectivesActivityBase,
  id: "dataDetectivesActivityCheckDependencies",
  label: "dataDetectivesActivityCheckDependencies",
  activitySteps: exampleDataDetectiveStepsWithDependencies,
};

export const dataDetectiveActivityUserProgress: IActivityUserData = {
  id: -1,
  activityId: dataDetectivesActivity.id,
  activeStepId: exampleDataDetectiveSteps[0].id,
  activityStatus: EActivityStatus.NOT_STARTED,
  stepsState: [],
  previousSteps: [],
  attemptNo: 0,
  dateCreated: String(Date.now()),
  dateModified: String(Date.now()),
};

export const DEMO_DATADETECTIVE_ACTIVITIES: Record<string | number, IActivity> = {
  dataDetectivesActivity,
  dataDetectivesActivityCheckDependencies,
};

export const DEMO_USER_DATADETECTIVE_ACTIVITIES: Record<string | number, IActivityUser | IActivityUserStarted> = {
  dataDetectivesActivity: { ...dataDetectivesActivity, activityUserProgress: dataDetectiveActivityUserProgress },
  dataDetectivesActivityCheckDependencies: {
    ...dataDetectivesActivityCheckDependencies,
    activityUserProgress: {
      ...dataDetectiveActivityUserProgress,
      activityId: dataDetectivesActivityCheckDependencies.id,
    },
  },
};
