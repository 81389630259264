import React from "react";
import createActivityDetector from "activity-detector";
import { AuthContext } from "react-oauth2-code-pkce";
import { useSearchParams } from "react-router-dom";
import {
  ESessionStatus,
  reduxGetUserData,
  reduxLogoutUser,
  returnFromLoginScreen,
  setIdle,
} from "../../Redux/User/userReducer";
import { useAppDispatch, useAppSelector } from "../../Redux/hooks";
import LoadingPanel from "../LoadingPanel/LoadingPanel";
import { useNavigate } from "react-router-dom";

export interface SessionManagerProps {
  alwaysShowChildren?: boolean;
  children: React.ReactNode;
}

export interface IIdleManagerProps {
  shortTermTimeout?: number;
  mediumTermTimeout?: number;
  longTermTimeout?: number;
}

// TODO: Link idle manager to redux state
export const useIdleManager = ({ shortTermTimeout, mediumTermTimeout, longTermTimeout }: IIdleManagerProps) => {
  const dispatch = useAppDispatch();
  const {
    user: { username },
  } = useAppSelector((state) => state.userState);

  const { logOut } = React.useContext(AuthContext);

  // Short Term Idle Detection
  React.useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: shortTermTimeout,
    });
    activityDetector.on("idle", () => {
      dispatch(setIdle());
    });

    activityDetector.on("active", () => {
      // dispatch(reduxReauthUser(username));
    });

    return () => {
      activityDetector.stop();
    };
  }, [dispatch, shortTermTimeout, username]);

  // Medium Term Idle Detection
  // reauthenticate on return
  React.useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: mediumTermTimeout,
      inactivityEvents: [],
      ignoredEventsWhenIdle: [],
    });
    activityDetector.on("idle", () => {
      dispatch(setIdle());
    });

    activityDetector.on("active", () => {
      // dispatch(reduxReauthUser(username));
    });

    return () => {
      activityDetector.stop();
    };
  }, [mediumTermTimeout, dispatch, username]);

  // lONG Term Idle Detection
  // Log user out
  React.useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: longTermTimeout,
      inactivityEvents: [],
      ignoredEventsWhenIdle: [],
    });
    activityDetector.on("idle", () => {
      // console.log('User is very idle');
      dispatch(setIdle());
    });

    activityDetector.on("active", () => {
      logOut();
    });

    return () => {
      activityDetector.stop();
    };
  }, [username, dispatch, longTermTimeout, logOut]);
};

export const SessionManager: React.FC<SessionManagerProps> = ({ alwaysShowChildren, children }) => {
  const { sessionState } = useAppSelector((state) => state.userState);
  const dispatch = useAppDispatch();
  const { token, login, logOut } = React.useContext(AuthContext);
  const monitorId = useAppSelector((state) => state.userState.state.monitor?.id);
  const [searchParams] = useSearchParams();
  const registered = React.useMemo(() => searchParams.get("registered"), [searchParams]);
  const loggedout = React.useMemo(() => searchParams.get("loggedout"), [searchParams]);
  const navigate = useNavigate();
  React.useEffect(() => {
    let timeout;
    if (sessionState === ESessionStatus.LOGGED_IN && token) {
      // check if there is a state param to redirect to
      const state = searchParams.get("state");
      if (state) {
        navigate(state);
      }
    }
    if (sessionState === ESessionStatus.RECEIVED_TOKEN && token) {
      /* User has returned from login screen with token */
      dispatch(reduxGetUserData(token, true, monitorId));
    }
    if (sessionState === ESessionStatus.NEW_SESSION && token) {
      /* User has refreshed page but has already logged in */
      // TODO: Check if token has expired.
      dispatch(returnFromLoginScreen());
    }
    if (sessionState === ESessionStatus.NEW_SESSION && !token) {
      /* New session and user is not logged in */
      dispatch(reduxLogoutUser());
    }
    if (sessionState === ESessionStatus.NEW_SESSION && registered) {
      timeout = setTimeout(() => login(), 2000);
      return () => {
        if (timeout) {
          // TODO: Should clear timeout but this cancels login
          // clearTimeout(timeout);
        }
      };
    }
    if (sessionState === ESessionStatus.LOGGED_IN && loggedout) {
      timeout = setTimeout(() => dispatch(reduxLogoutUser(logOut)), 200);
      return () => {
        if (timeout) {
          // TODO: Should clear timeout but this cancels logOut clearTimeout(timeout);
        }
      };
    }
  }, [dispatch, sessionState, token, loggedout, logOut, login, registered, navigate, searchParams, monitorId]);

  const loadingMessage =
    (sessionState === ESessionStatus.GETTING_TOKEN && "Getting session token...") ||
    (sessionState === ESessionStatus.RECEIVED_TOKEN && "Processing session token...") ||
    (sessionState === ESessionStatus.GETTING_USER_META_DATA && "Getting user data...") ||
    (sessionState === ESessionStatus.LOGGING_OUT && "Logging out...") ||
    null;

  const showLoadingPanel = loadingMessage !== null && false; //TODO: Temporarily disabled loading panel

  return (
    <>
      <LoadingPanel className="sessionManager_loadingPanel" open={showLoadingPanel} message={loadingMessage} />
      {((sessionState === ESessionStatus.LOGGED_IN && token) || alwaysShowChildren) && children}
      {sessionState !== ESessionStatus.LOGGED_IN && !alwaysShowChildren && sessionState}
    </>
  );
};

