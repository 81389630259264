import { EFilterType, ILabelled } from "@reactdbclient/types.client.common";
import { IActivityScore } from "../Achievements/EAchievementCategory";
import { IAchievement } from "../Achievements/IAchievement";
import { ImageUrl } from "../Primatives/Paths";
import { ITag } from "../Tags/ITag";
import { EEquipmentNeeded } from "./EEquipmentNeeded";
import { EGrades } from "./EGrades";
import { EThemes } from "./EThemes";
import { IStep, IStepSubmission, IStepUserData, TStepAdditionalProps, TStepId, TStepRef, TStepState } from "./IStep";
import { UserGroup } from "../User/UserGroup";
import { FilterOptionExtended } from "../../GenericComponents/CardBoard/Filters/FilterState";
import { generateFiltersFromFieldsExtended } from "../../GenericComponents/CardBoard/utils";

export type TActivityId = string | number;

export enum EActivityTypes {
  TEST = -1,
  STEP_BY_STEP = 1,
  DATA_DETECTIVES = 2,
  QUIZ = 3,
}

export const ActivityTypes: Record<EActivityTypes, ILabelled> = {
  [EActivityTypes.TEST]: {
    uid: EActivityTypes.TEST,
    label: "TEST",
  },
  [EActivityTypes.STEP_BY_STEP]: {
    uid: EActivityTypes.STEP_BY_STEP,
    label: "STEP_BY_STEP",
  },
  [EActivityTypes.DATA_DETECTIVES]: {
    uid: EActivityTypes.DATA_DETECTIVES,
    label: "DATA_DETECTIVES",
  },
  [EActivityTypes.QUIZ]: {
    uid: EActivityTypes.QUIZ,
    label: "QUIZ",
  },
};

export type Time = number;

export interface IActivityTimeEstimates {
  investigation: Time;
  video: Time;
  discussion: Time;
}

export interface IActivityBasicData {
  id: TActivityId;
  label: string;
  logo?: ImageUrl; // Superceeded by imageUrl?
  type: EActivityTypes;
  description: string;
  longDescription: string;
  timeEstimates?: IActivityTimeEstimates;
  activitySteps: IStep<TStepAdditionalProps>[];
  targetGrade?: EGrades[];
  imageUrl?: ImageUrl;
  theme: EThemes;
  tags?: ITag[];
  bannerImageUrl?: ImageUrl;
  nextActivityIds: TActivityId[];
  previousActivityIds: TActivityId[];
  targetRoles?: UserGroup[];
}

export interface IActivityCheatCodes {
  complete?: string;
}
export interface IActivityAdditionalData {
  textFilePath?: string;
  noOfPeople?: string;
  isTeacherRequired?: boolean;
  isSuperUserRequired?: boolean;
  equipmentNeeded?: EEquipmentNeeded[];
  equipmentNeededDetailed?: string[];
  activityScores: IActivityScore[];
  achievements?: [IAchievement];
  cheatCodes?: IActivityCheatCodes;
  feedbackNeeded?: boolean;
}

export interface IActivity extends IActivityBasicData, IActivityAdditionalData {}

export enum EActivityStatus {
  NOT_STARTED = 0,
  IN_PROGRESS = 1,
  FAILED = 2,
  COMPLETED = 3,
}

export const ActivityStatus: Record<EActivityStatus, ILabelled> = {
  [EActivityStatus.NOT_STARTED]: {
    uid: EActivityStatus.NOT_STARTED,
    label: "Ready to start",
  },
  [EActivityStatus.IN_PROGRESS]: {
    uid: EActivityStatus.IN_PROGRESS,
    label: "In progress",
  },
  [EActivityStatus.FAILED]: {
    uid: EActivityStatus.FAILED,
    label: "failed",
  },
  [EActivityStatus.COMPLETED]: {
    uid: EActivityStatus.COMPLETED,
    label: "Completed",
  },
};

export interface IActivityUserData {
  id: number;
  isLocked?: boolean;
  activityId: TActivityId;
  activityStatus: EActivityStatus;
  dateCreated: string;
  dateModified: string;
  stepsState: IStepUserData<TStepState>[];
  activeStepId: TStepId;
  previousSteps: TStepRef[];
  attemptNo: number;
}

export interface IActivityUserDataNotStarted {
  id?: undefined;
  isLocked?: boolean;
  dateModified?: undefined;
  dateCreated?: undefined;
  activityId: TActivityId;
  stepsState?: undefined;
  activityStatus: EActivityStatus.NOT_STARTED;
  activeStepId?: undefined;
  previousSteps?: undefined;
  attemptNo?: undefined;
}

export interface IActivityApiData {
  activity: IActivity;
  latestActivityUserProgress: IActivityUserData | IActivityUserDataNotStarted;
  activityIsLocked: boolean;
}

export interface IMissingActivityData {
  id: TActivityId;
  label: "Missing activity";
  missingData: true;
}

export interface IActivityUser extends IActivity {
  missingData?: false;
  activityUserProgress: IActivityUserData | IActivityUserDataNotStarted;
  previousActivities?: (IActivityUser | IMissingActivityData)[];
  previousActivitiesRequirement?: (IActivityUser | IMissingActivityData)[];
}

export interface IActivityUserStarted extends IActivity {
  activityUserProgress: IActivityUserData;
}

export interface IActivitySubmitData {
  data: IStepSubmission<TStepState>;
  nextStep: TStepId;
}


export const ActivityViewFields: FilterOptionExtended[] = [
  new FilterOptionExtended(
    {
      uid: "showLocked",
      field: "showLocked",
      label: "Locked",
      type: EFilterType.bool,
      value: true,
    },
    { icon: "/images/app/icons/Locked.png", value: true }
  ),
  new FilterOptionExtended(
    {
      uid: "showReady",
      field: "showReady",
      label: "Ready",
      type: EFilterType.bool,
      value: true,
    },
    { icon: "/images/app/icons/Ready.png", value: true }
  ),
  new FilterOptionExtended(
    {
      uid: "showCompleted",
      field: "showCompleted",
      label: "Completed",
      type: EFilterType.bool,
      value: true,
    },
    { icon: "/images/app/icons/Complete.png", value: true }
  ),
  new FilterOptionExtended(
    {
      uid: "showInProgress",
      field: "showInProgress",
      label: "In Progress",
      type: EFilterType.bool,
      value: true,
    },
    { icon: "/images/app/icons/In_Progress.png", value: true }
  ),
  new FilterOptionExtended(
    {
      uid: "equipmentNeeded",
      field: "equipmentNeeded",
      label: "Equipment needed",
      type: EFilterType.bool,
      value: true,
    },
    { icon: "/images/app/icons/Equipment_Needed.png", value: false }
  ),
];

export const initialFilterData = generateFiltersFromFieldsExtended(ActivityViewFields);

export enum EFilterButton {
  LOCKED = "LOCKED",
  READY = "READY",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
}
