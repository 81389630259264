import React from "react";
import { EFilterType, FilterObjectClass, FilterOption } from "@reactdbclient/types.client.common";
import { useGetFilterComponents } from "@reactdbclient/components.filter-manager.filter-hooks";
import { FilterElement, FilterMenuContentStyle } from "./styles";
import { SelectMultiFilter } from "./customFilterComponents";
import FilterSelect from "./customFilterComponents/SelectFilter";
import { useManageFilters } from "../Filters/useManageFilters";

export interface IFilterMenuProps {
  fields: { [key: string]: FilterOption };
  filtersManager: ReturnType<typeof useManageFilters>;
}

const customFiltersComponents = {
  [EFilterType.selectMulti]: SelectMultiFilter,
  [EFilterType.select]: FilterSelect as any, // TODO: Fix this type error
};

export interface IFilterWrapperProps {
  filter: FilterObjectClass;
  filterComponent: React.ReactNode;
}

export const FilterWrapper = ({ filter, filterComponent }: IFilterWrapperProps) => (
  <FilterElement key={filter.uid} data-testid={`filter ${filter.uid}`}>
    <h1>{filter.label}</h1>
    <div>{filterComponent}</div>
  </FilterElement>
);

export const FilterMenu = ({ fields, filtersManager }: IFilterMenuProps) => {
  const updateFilter = React.useCallback(
    (filterId, filterData) => {
      filtersManager.updateFilter(filterId, filterData);
    },
    [filtersManager]
  );
  const filterComponents = useGetFilterComponents({
    filterData: filtersManager.filters,
    fieldsData: fields,
    updateFilter,
    customFiltersComponents,
    filterMap: {} as any /* NOTE: We do not use any default filters here */
  });
  return (
    <FilterMenuContentStyle data-testid="cardboardFilterMenu">
      {filtersManager.filters.map((filter, i) => (
        <FilterWrapper key={filter.uid} filter={filter} filterComponent={filterComponents[i]} />
      ))}
    </FilterMenuContentStyle>
  );
};
