import { FilterObjectClass } from "@reactdbclient/types.client.common";
import { EResourceType, IAppResource } from "../../lib/AppResources/IAppResource";
import { UserGroup } from "../../lib/User/UserGroup";
import { useAppSelector } from "../../Redux/hooks";
import { IResourceCardData } from "./AppResourcesCard/IAppResourceCardProps";

export interface IUseGetResourcesMetaDataArgs {
  filters?: FilterObjectClass[];
}

/* Resources are currently hard coded */
export const resources: IAppResource[] = [
  {
    id: "samheCert1",
    label: "SAMHE Hero Certificate 1",
    description: "Click the download link below to download our exclusive SAMHE Hero Certificate.",
    resourceType: EResourceType.CERTIFICATE,
    resourceUrl: "/app/certificates/SAMHE_CERTIFICATE_1.docx",
    thumbnailUrl: "/app/certificates/SAMHE_certificate_1_SMALL.jpg",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "samheCert2",
    label: "SAMHE Hero Certificate 2",
    description: "Click the download link below to download our exclusive SAMHE Hero Certificate.",
    resourceType: EResourceType.CERTIFICATE,
    resourceUrl: "/app/certificates/SAMHE_CERTIFICATE_2.docx",
    thumbnailUrl: "/app/certificates/SAMHE_certificate_2_SMALL.jpg",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "samhe_parent_information_pack",
    label: "SAMHE Parent information pack",
    description:
      "This 'parent information pack' contains templates designed to help you communicate with parents/guardians about taking part in SAMHE. Please use these in combination with your usual processes and adapt the templates as required.",
    resourceType: EResourceType.DOWNLOAD,
    resourceUrl: "/resources/SAMHE_Parent_information_pack.pdf",
    thumbnailUrl: "",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "samhe_monitor_sign",
    label: "SAMHE Monitor sign",
    description: `This A5 "Hi, I'm your SAMHE monitor" poster can be put next to your monitor to remind people what it is doing and why (and encourage them not to interfere with the monitor without permission).`,
    resourceType: EResourceType.DOWNLOAD,
    resourceUrl: "/resources/samhe_monitor_sign.zip",
    thumbnailUrl: "",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "we_are_part_of_samhe",
    label: `We're part of SAMHE" poster`,
    description: `You could use this A4 'We are part of SAMHE!' poster in your classroom, on a school notice board, or as part of a display, to celebrate being part of SAMHE. The poster also promotes the SAMHE website, to help more people learn about SAMHE.`,
    resourceType: EResourceType.DOWNLOAD,
    resourceUrl: "/resources/we_are_part_of_samhe.zip",
    thumbnailUrl: "",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "activity_completion_codes",
    label: `Activity Completion Codes`,
    description: `This is a list of completion codes which can be shared with your pupils to mark activities as complete in their Web App accounts. This might be useful if you're completing activities as a class and would like to share credit between everyone involved, or you'd like to bypass our 'locking' mechanism which guides pupils to complete activities in a given order.`,
    resourceType: EResourceType.POPUP_INFO,
    resourceUrl: "/app/library/activity_completion_codes",
    thumbnailUrl: "",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "extra_teacher_notes",
    label: `Extra guidance for Teachers`,
    description: `This card contains information on:
- How to set up accounts for other users
- Key differences between staff and pupil accounts
- How to direct pupils to specific activities
- How to use SAMHE to support your curriculum-based teaching
- How to be part of a mutually supportive community of SAMHE users!
    `,
    resourceType: EResourceType.POPUP_INFO,
    resourceUrl: "/app/library/extra_teacher_notes",
    thumbnailUrl: "/images/app/home/app-robot-1.png",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "videos_to_inspire_and_help",
    label: "Videos to inspire and help!",
    description: `Do you like to receive information in video format? We've got you covered with our [SAMHE YouTube channel](https://www.youtube.com/channel/UCEp7Uce52tS5wd8Asvgt2MA)! Try watching:

-   [Introducing SAMHE](https://www.youtube.com/watch?v=fL33sLJDdFQ&list=PLy5kP5wcN-NXmH3X4M11eZEa0iOX3obIL&index=1) (3 min) for a quick overview of the project
-   [Web App Walkthrough: A whistlestop tour!](https://youtu.be/LCh-EiSnczw) (3 min) does what it says on the tin!
-   [Web App Activities](https://www.youtube.com/watch?v=6VLvedLvxMM&list=PLy5kP5wcN-NXmH3X4M11eZEa0iOX3obIL&index=3&t=93s) (4 min) shows how the Web App activities work
-   [Teacher testimonials](https://www.youtube.com/watch?v=8fhvbjLUMtg&list=PLy5kP5wcN-NXmH3X4M11eZEa0iOX3obIL&index=4) (14 min) be inspired by four teachers in very different schools explaining how they are using SAMHE
-   [Educational videos](https://www.youtube.com/playlist?list=PLy5kP5wcN-NXnklGwf6TLID-JYtwx9uZh) on the science and maths of ventilation.
-   [Guidance for teachers](https://youtube.com/playlist?list=PLy5kP5wcN-NWGOBSOkjyrLQY8envhIWF5&si=-dss39zNBWXA6HQw), our partners at [www.CoSchools.org.uk](http://www.coschools.org.uk) produced videos to explain the use of air filters and CO₂ monitors in school classrooms
-   [Schools' webinar on SAMHE findings](https://www.youtube.com/watch?v=EgenIlp6w3g) (38 mins) a webinar held in July 2024 to share our findings on air quality in UK schools in an accessible way with pupils and staff at SAMHE schools.
`,
    resourceType: EResourceType.PLAIN_TEXT,
    resourceUrl: "",
    thumbnailUrl: "",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
  {
    id: "monitor_leds_poster",
    label: `Monitor LEDs poster`,
    description: `This A4 'Monitor LEDs' poster provides an overview of what the LEDs on the front of your SAMHE monitor tell you about CO\u2082 levels, what those levels mean for your wellbeing, and gives some recommended actions.`,
    resourceType: EResourceType.DOWNLOAD,
    resourceUrl: "/resources/A4_SAMHE_LED_poster.pdf",
    thumbnailUrl: "",
    restrictedAccessGroups: [UserGroup.SUPERADMIN, UserGroup.SCHOOLADMIN, UserGroup.TEACHER],
  },
];

export const resourceCardData: IResourceCardData[] = resources.map((r) => ({
  ...r,
  title: r.label,
  uniqueCardId: r.id,
  type: r.resourceType,
}));

export const useGetResourcesMetaData = ({ filters: _filters }: IUseGetResourcesMetaDataArgs) => {
  const {
    user: { group },
  } = useAppSelector((state) => state.userState);
  // TODO: Implement filters
  return resourceCardData.filter(
    (c) =>
      !c.restrictedAccessGroups ||
      c.restrictedAccessGroups.length === 0 ||
      c.restrictedAccessGroups.indexOf(group) !== -1
  );
};
